import styles from "./Tutorials.module.css";

function Tutorials({ tutorials, popupSelected }) {
  const steps = Object.keys(tutorials[popupSelected]).length;

  return (
    <div className={styles.section}>
      <h1>Guia básico {popupSelected}</h1>
      {Object.entries(tutorials[popupSelected]).map(([step, infos], index) => (
        <>
          <h2>{step}</h2>
          <div className={styles.separator}>
            {Array.from({ length: steps }).map((_, i) => (
              <div
                key={i}
                className={`${styles.segment} ${i === index ? styles.active : ""}`}
              ></div>
            ))}
          </div>
          <img
            className={styles.tutorialImage}
            src={require("../utils/img/" + infos["image"])}
            alt={step}
          ></img>
          {infos.description.map((desc, index) => (
            <p>{desc}</p>
          ))}
        </>
      ))}
    </div>
  );
}

export default Tutorials;
