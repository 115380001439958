import styles from "./ProductIcon.module.css";
import LicenseTag from "./LicenseTag";

function ProductIcon({ product, plan }) {
  return (
    <div className={styles.productIcon}>
      <img
        src={require("../utils/img/" + product + "-icon.png")}
        alt={`${product + plan} icon`}
      ></img>
      {plan && <LicenseTag plan={plan} style={{ position: "absolute" }} />}
    </div>
  );
}

export default ProductIcon;
