import { useState } from "react";
import styles from "./Calculators.module.css";

import { FaArrowLeft } from "react-icons/fa";

import Bending from "./components/calculators/concrete/Bending";
import Shear from "./components/calculators/concrete/Shear";
import { IconButton } from "@chakra-ui/react";

function Calculators({ calculators }) {
  const [selectedCalculator, setSelectedCalculator] = useState("menu");

  return (
    <>
      {selectedCalculator !== "menu" && (
        <div className={styles.section} key={"return"}>
          <IconButton
            variant={"icon"}
            icon={<FaArrowLeft />}
            onClick={() => setSelectedCalculator("menu")}
          />
        </div>
      )}
      {selectedCalculator === "menu" && (
        <>
          {Object.entries(calculators).map(([subject, subjectInfos], i) => (
            <div className={styles.section} key={"menu"}>
              <div className={styles.sectionHeader}>
                <img
                  src={require("../utils/img/" + subjectInfos["icon"])}
                  alt={`${subject} icon`}
                ></img>
                <h1>{subject}</h1>
              </div>
              <div className={styles.sectionSeparator}></div>
              <div className={styles.cards}>
                {subjectInfos["calculators"].map((calculator, j) => (
                  <div
                    className={styles.card}
                    key={j}
                    onClick={() =>
                      setSelectedCalculator(calculator["calculator"])
                    }
                  >
                    {calculator["name"]}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
      {selectedCalculator === "bending" && <Bending />}
      {selectedCalculator === "shear" && <Shear />}
    </>
  );
}

export default Calculators;
