import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase.js";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import * as jwt_decode from "jwt-decode";

import { api } from "./utils/services/api";

import Homepage from "./applications/homepage/Main";
import Fundars from "./applications/fundars/Main";
import Quantitars from "./applications/quantitars/Main";
import Complas from "./applications/complas/Main";

function App() {
  const [userInputs, setUserInputs] = useState({
    id: null,
    name: "",
    email: "",
    licenses: {},
  });

  function updateUserInputs(key, value) {
    setUserInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // Se o usuário estiver autenticado, obtém o token JWT
        firebaseUser.getIdToken().then((idToken) => {
          api.post("/verify-token", { idToken }).then((response) => {
            updateUserInputs("id", response["data"]["id"]);
            updateUserInputs("name", response["data"]["name"]);
            updateUserInputs("email", response["data"]["email"]);
            api
              .post("/subscription", { id: response["data"]["id"] })
              .then((response) => {
                updateUserInputs("licenses", response["data"]);
              });
          });
        });
      }
    });
    return () => unsubscribe(); // Remove o listener quando o componente desmontar
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Homepage
                userInputs={userInputs}
                updateUserInputs={updateUserInputs}
              />
            }
          />
          <Route
            path="/fundars/*"
            element={<Fundars userInputs={userInputs} />}
          />
          <Route
            path="/quantitars"
            element={<Quantitars userInputs={userInputs} />}
          />
          <Route
            path="/complas"
            element={<Complas userInputs={userInputs} />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
