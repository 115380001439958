import {
  Header,
  Footer,
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  VerticalAlign,
  PageOrientation,
  ImageRun,
  BorderStyle,
} from "docx";
import { saveAs } from "file-saver";
import logo from "../../../../utils/img/engenhars_logo.png";

async function concretingsReportBuilder(
  userInputs,
  workInputs,
  concretings,
  reportInputs
) {
  // Text Style
  const normalText = (text) => new TextRun({ text, font: "Arial", size: 22 });
  const boldText = (text) =>
    new TextRun({ text, font: "Arial", size: 22, bold: true });
  const titleText = (text) =>
    new TextRun({ text, font: "Arial", size: 30, bold: true });
  const greenText = (text) =>
    new TextRun({ text, font: "Arial", size: 22, color: "00B050" });
  const redText = (text) =>
    new TextRun({ text, font: "Arial", size: 22, color: "FF0000" });

  function formatDate(date) {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  const header = new Header({
    children: [
      new Table({
        borders: {
          top: BorderStyle.NONE,
          bottom: BorderStyle.NONE,
          left: BorderStyle.NONE,
          right: BorderStyle.NONE,
          insideVertical: BorderStyle.NONE,
        },
        width: { size: 100, type: "pct" },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "LOGO EMPRESA", // Substitua por uma imagem real
                        size: 22, // 14 pt
                      }),
                    ],
                    alignment: AlignmentType.LEFT, // Alinha à esquerda
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "LOGO ENGENHARS", // Substitua por uma imagem real
                        size: 22, // 14 pt
                      }),
                    ],
                    alignment: AlignmentType.RIGHT, // Alinha à direita
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
            ],
          }),
        ],
      }),
    ],
  });

  const footer = new Footer({
    children: [
      new Paragraph({
        children: [normalText(new Date().toLocaleDateString())],
        alignment: AlignmentType.RIGHT,
      }),
    ],
  });

  function concretingsPageBuilder(concreting) {
    let place = null;
    if (workInputs["towers"].length !== 0) {
      if (concreting["description"] === "") {
        place = concreting["tower"] + " - " + concreting["level"];
      } else {
        place =
          concreting["tower"] +
          " - " +
          concreting["level"] +
          " - " +
          concreting["description"];
      }
    } else {
      if (concreting["description"] === "") {
        place = concreting["level"];
      } else {
        place = concreting["level"] + " - " + concreting["description"];
      }
    }

    let volume = 0;
    concreting["trucks"].map((truck) => {
      volume = volume + truck["volume"];
    });

    const section = [
      new Paragraph({
        children: [titleText("RELATÓRIO DE CONCRETAGEM")],
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({ text: "" }), // Linha em branco
      new Paragraph({
        children: [boldText("CLIENTE: "), normalText(userInputs["name"])],
      }),
      new Paragraph({
        children: [boldText("CIDADE: "), normalText(workInputs["city"])],
      }),
      new Paragraph({
        children: [boldText("OBRA: "), normalText(workInputs["selected_name"])],
      }),
      new Paragraph({
        children: [boldText("LOCAL DA CONCRETAGEM: "), normalText(place)],
      }),
      new Paragraph({
        children: [
          boldText("VOLUME TOTAL CONCRETADO: "),
          normalText(volume.toString() + " m³"),
          new TextRun("\t\t"),
          new TextRun("\t\t"),
          boldText("FCK (MPa): "),
          normalText(concreting["fck"]),
          new TextRun("\t\t"),
          new TextRun("\t\t"),
          boldText("DATA CONCRETAGEM: "),
          normalText(formatDate(concreting["date"])),
        ],
      }),
      new Paragraph({ text: "" }), // Linha em branco
    ];

    const tableRows = [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("ROMANEIO")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            rowSpan: 2,
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("ELEMENTO CONCRETADO")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            rowSpan: 2,
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("VOLUME")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            rowSpan: 2,
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("CORPOS DE PROVA")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            columnSpan: workInputs["test_bodies"].length,
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
      new TableRow({
        children: [
          ...workInputs["test_bodies"].map((testBody) => {
            return new TableCell({
              children: [
                new Paragraph({
                  children: [boldText(testBody.toString() + "d")],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            });
          }),
        ],
      }),
    ];
    concreting["trucks"].map((truck) => {
      const testBodyExists = truck["test_bodies"].some(
        (testBody) => testBody !== "-"
      );
      if (testBodyExists) {
        tableRows.push(
          new TableRow({
            children: Object.entries(truck)
              .map(([key, value]) => {
                if (key !== "test_bodies") {
                  // Retorna a célula com o valor
                  return new TableCell({
                    children: [
                      new Paragraph({
                        children: [normalText(value.toString())],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  });
                } else if (Array.isArray(value) && value.length > 0) {
                  // Para cada item de "test_bodies", cria uma célula
                  return value.map((testBody, testBodyIndex) => {
                    if (testBody === "-") {
                      return new TableCell({
                        children: [
                          new Paragraph({
                            children: [normalText(testBody.toString())],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                      });
                    } else {
                      if (
                        testBody >=
                        Math.exp(
                          0.25 *
                            (1 -
                              Math.pow(
                                28 / workInputs["test_bodies"][testBodyIndex],
                                0.5
                              ))
                        ) *
                          concreting["fck"]
                      ) {
                        return new TableCell({
                          children: [
                            new Paragraph({
                              children: [greenText(testBody.toString())],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        });
                      } else {
                        return new TableCell({
                          children: [
                            new Paragraph({
                              children: [redText(testBody.toString())],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        });
                      }
                    }
                  });
                }
              })
              .flat(),
          })
        );
      }
    });

    const table = new Table({
      rows: tableRows,
      width: {
        size: 100,
        type: "pct",
      },
    });

    return [...section, table];
  }

  function resultsPageBuilder(concretings) {
    const section = [
      new Paragraph({
        children: [titleText("RELATÓRIO DE RESULTADOS ABAIXO DO ESPERADO")],
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({ text: "" }),
      new Paragraph({
        children: [boldText("CLIENTE: "), normalText(userInputs["name"])],
      }),
      new Paragraph({
        children: [boldText("CIDADE: "), normalText(workInputs["city"])],
      }),
      new Paragraph({
        children: [boldText("OBRA: "), normalText(workInputs["selected_name"])],
      }),
    ];

    const tableRows = [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("DATA")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            rowSpan: 2,
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("ROMANEIO")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            rowSpan: 2,
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("LOCAL")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            rowSpan: 2,
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("ELEMENTO CONCRETADO")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            rowSpan: 2,
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("FCK (MPa)")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            rowSpan: 2,
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("VOLUME (m³)")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            rowSpan: 2,
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("CORPOS DE PROVA")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            columnSpan: workInputs["test_bodies"].length,
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
      new TableRow({
        children: [
          ...workInputs["test_bodies"].map((testBody) => {
            return new TableCell({
              children: [
                new Paragraph({
                  children: [boldText(testBody.toString() + "d")],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            });
          }),
        ],
      }),
    ];
    concretings.map((concreting) => {
      let place = null;
      if (workInputs["towers"].length !== 0) {
        if (concreting["description"] === "") {
          place = concreting["tower"] + " - " + concreting["level"];
        } else {
          place =
            concreting["tower"] +
            " - " +
            concreting["level"] +
            " - " +
            concreting["description"];
        }
      } else {
        if (concreting["description"] === "") {
          place = concreting["level"];
        } else {
          place = concreting["level"] + " - " + concreting["description"];
        }
      }

      let volume = 0;
      concreting["trucks"].map((truck) => {
        volume = volume + truck["volume"];
      });

      concreting["trucks"].map((truck) => {
        for (
          let testBodyIndex = 0;
          testBodyIndex < truck["test_bodies"].length;
          testBodyIndex++
        ) {
          const testBody = truck["test_bodies"][testBodyIndex];
          if (
            testBody !== "-" &&
            testBody <
              Math.exp(
                0.25 *
                  (1 -
                    Math.pow(
                      28 / workInputs["test_bodies"][testBodyIndex],
                      0.5
                    ))
              ) *
                concreting["fck"]
          ) {
            tableRows.push(
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [normalText(formatDate(concreting["date"]))],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [normalText(truck["waybill"].toString())],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [normalText(place)],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [normalText(truck["element"])],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [normalText(concreting["fck"])],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [normalText(volume.toString())],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  ...truck["test_bodies"].map((testBody) => {
                    if (
                      testBody >=
                      Math.exp(
                        0.25 *
                          (1 -
                            Math.pow(
                              28 / workInputs["test_bodies"][testBodyIndex],
                              0.5
                            ))
                      ) *
                        concreting["fck"]
                    ) {
                      return new TableCell({
                        children: [
                          new Paragraph({
                            children: [greenText(testBody.toString())],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                      });
                    } else {
                      return new TableCell({
                        children: [
                          new Paragraph({
                            children: [redText(testBody.toString())],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                      });
                    }
                  }),
                ],
              })
            );
            break;
          }
        }
      });
    });

    const table = new Table({
      rows: tableRows,
      width: {
        size: 100,
        type: "pct",
      },
    });

    return [...section, table];
  }

  function levelMensurationPageBuilder(concretings) {
    const section = [
      new Paragraph({
        children: [
          titleText("RELATÓRIO DE CONCRETAGEM - MEDIÇÃO POR PAVIMENTO"),
        ],
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({ text: "" }), // Linha em branco
      new Paragraph({
        children: [boldText("CLIENTE: "), normalText(userInputs["name"])],
      }),
      new Paragraph({
        children: [boldText("CIDADE: "), normalText(workInputs["city"])],
      }),
      new Paragraph({
        children: [boldText("OBRA: "), normalText(workInputs["selected_name"])],
      }),
      new Paragraph({
        children: [
          boldText("PERIODO/DATA DE CONCRETAGEM: "),
          normalText("Ajustar"),
        ],
      }),
      new Paragraph({ text: "" }), // Linha em branco
    ];

    const concretingsByFck = concretings.reduce((groupedByFck, concreting) => {
      const fck = concreting.fck; // Pegando o valor de fck como chave
      if (!groupedByFck[fck]) {
        groupedByFck[fck] = []; // Criando o array se ainda não existir
      }
      groupedByFck[fck].push(concreting); // Adicionando o objeto ao array correspondente
      return groupedByFck;
    }, {});

    const tableRows = [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("FCK (MPa)")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          ...(workInputs["towers"].length !== 0
            ? [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [boldText("SETOR")],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
              ]
            : []),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("PAVIMENTO")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("VOLUME (m³)")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
    ];

    Object.entries(concretingsByFck).map(([fck, concretings]) => {
      let concretingsByPlace = null;
      if (workInputs["towers"].length === 0) {
        concretingsByPlace = concretings.reduce(
          (groupedByLevel, concreting) => {
            const level = concreting.level;
            if (!groupedByLevel[level]) {
              groupedByLevel[level] = [];
            }
            groupedByLevel[level].push(concreting);

            return groupedByLevel;
          },
          {}
        );
      } else {
        concretingsByPlace = concretings.reduce(
          (groupedByTower, concreting) => {
            const tower = concreting.tower;
            if (!groupedByTower[tower]) {
              groupedByTower[tower] = {};
            }

            const level = concreting.level;
            if (!groupedByTower[tower][level]) {
              groupedByTower[tower][level] = [];
            }
            groupedByTower[tower][level].push(concreting);

            return groupedByTower;
          },
          {}
        );
      }
      Object.entries(concretingsByPlace).map(([level, concretings], i) => {
        if (Array.isArray(concretings)) {
          // Project without towers application
          let volume = 0;
          concretings.map((concreting) => {
            concreting["trucks"].map((truck) => {
              volume = volume + truck["volume"];
            });
          });
          tableRows.push(
            new TableRow({
              children: [
                ...(i === 0
                  ? [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [normalText(fck)],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        rowSpan: Object.keys(concretingsByPlace).length,
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                    ]
                  : []),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [normalText(level)],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [normalText(volume.toString())],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
              ],
            })
          );
        } else {
          // Project with towers application
          const tower = level;
          const concretingByLevel = concretings;

          Object.entries(concretingByLevel).map(([level, concretings], j) => {
            let volume = 0;
            concretings.map((concreting) => {
              concreting["trucks"].map((truck) => {
                volume = volume + truck["volume"];
              });
            });
            tableRows.push(
              new TableRow({
                children: [
                  ...(i === 0 && j === 0
                    ? [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [normalText(fck)],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          rowSpan: Object.values(concretingsByPlace)
                            .flatMap(Object.values)
                            .reduce((acc, arr) => acc + arr.length, 0),
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    : []),
                  ...(j === 0
                    ? [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [normalText(tower)],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          rowSpan: Object.keys(concretingsByPlace).length,
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    : []),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [normalText(level)],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [normalText(volume.toString())],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                ],
              })
            );
          });
        }
      });
    });

    const table = new Table({
      rows: tableRows,
      width: {
        size: 100,
        type: "pct",
      },
    });

    return [...section, table];
  }

  function descriptionMensurationPageBuilder(concretings) {
    const section = [
      new Paragraph({
        children: [
          titleText("RELATÓRIO DE CONCRETAGEM - MEDIÇÃO POR ELEMENTO"),
        ],
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({ text: "" }), // Linha em branco
      new Paragraph({
        children: [boldText("CLIENTE: "), normalText(userInputs["name"])],
      }),
      new Paragraph({
        children: [boldText("CIDADE: "), normalText(workInputs["city"])],
      }),
      new Paragraph({
        children: [boldText("OBRA: "), normalText(workInputs["selected_name"])],
      }),
      new Paragraph({
        children: [
          boldText("PERIODO/DATA DE CONCRETAGEM: "),
          normalText("Ajustar"),
        ],
      }),
      new Paragraph({ text: "" }), // Linha em branco
    ];

    const concretingsByFck = concretings.reduce((groupedByFck, concreting) => {
      const fck = concreting.fck; // Pegando o valor de fck como chave
      if (!groupedByFck[fck]) {
        groupedByFck[fck] = []; // Criando o array se ainda não existir
      }
      groupedByFck[fck].push(concreting); // Adicionando o objeto ao array correspondente
      return groupedByFck;
    }, {});

    const tableRows = [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("FCK (MPa)")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("ELEMENTO")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("VOLUME (m³)")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
    ];

    Object.entries(concretingsByFck).map(([fck, concretings]) => {
      const concretingsByElement = concretings.reduce(
        (groupedByElement, concreting) => {
          const description = concreting.description;
          if (!groupedByElement[description]) {
            groupedByElement[description] = [];
          }
          groupedByElement[description].push(concreting);

          return groupedByElement;
        },
        {}
      );
      Object.entries(concretingsByElement).map(
        ([description, concretings], i) => {
          let volume = 0;
          concretings.map((concreting) => {
            concreting["trucks"].map((truck) => {
              volume = volume + truck["volume"];
            });
          });
          tableRows.push(
            new TableRow({
              children: [
                ...(i === 0
                  ? [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [normalText(fck)],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        rowSpan: Object.keys(concretingsByElement).length,
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                    ]
                  : []),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [normalText(description)],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [normalText(volume.toString())],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
              ],
            })
          );
        }
      );
    });

    const table = new Table({
      rows: tableRows,
      width: {
        size: 100,
        type: "pct",
      },
    });

    return [...section, table];
  }

  function placeMensurationPageBuilder(concretings) {
    const section = [
      new Paragraph({
        children: [
          titleText(
            "RELATÓRIO DE CONCRETAGEM - MEDIÇÃO POR ELEMENTO EM PAVIMENTO"
          ),
        ],
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({ text: "" }), // Linha em branco
      new Paragraph({
        children: [boldText("CLIENTE: "), normalText(userInputs["name"])],
      }),
      new Paragraph({
        children: [boldText("CIDADE: "), normalText(workInputs["city"])],
      }),
      new Paragraph({
        children: [boldText("OBRA: "), normalText(workInputs["selected_name"])],
      }),
      new Paragraph({
        children: [
          boldText("PERIODO/DATA DE CONCRETAGEM: "),
          normalText("Ajustar"),
        ],
      }),
      new Paragraph({ text: "" }), // Linha em branco
    ];

    const concretingsByFck = concretings.reduce((groupedByFck, concreting) => {
      const fck = concreting.fck; // Pegando o valor de fck como chave
      if (!groupedByFck[fck]) {
        groupedByFck[fck] = []; // Criando o array se ainda não existir
      }
      groupedByFck[fck].push(concreting); // Adicionando o objeto ao array correspondente
      return groupedByFck;
    }, {});

    const tableRows = [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("FCK (MPa)")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          ...(workInputs["towers"].length !== 0
            ? [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [boldText("SETOR")],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
              ]
            : []),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("PAVIMENTO")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("ELEMENTO")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [boldText("VOLUME (m³)")],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
    ];

    Object.entries(concretingsByFck).map(([fck, concretings]) => {
      let concretingsByPlace = null;
      if (workInputs["towers"].length === 0) {
        concretingsByPlace = concretings.reduce(
          (groupedByPlace, concreting) => {
            const level = concreting.level;
            if (!groupedByPlace[level]) {
              groupedByPlace[level] = {};
            }

            const description = concreting.description;
            if (!groupedByPlace[level][description]) {
              groupedByPlace[level][description] = [];
            }
            groupedByPlace[level][description].push(concreting);

            return groupedByPlace;
          },
          {}
        );
      } else {
        concretingsByPlace = concretings.reduce(
          (groupedByPlace, concreting) => {
            const tower = concreting.tower;
            if (!groupedByPlace[tower]) {
              groupedByPlace[tower] = {};
            }

            const level = concreting.level;
            if (!groupedByPlace[tower][level]) {
              groupedByPlace[tower][level] = {};
            }

            const description = concreting.description;
            if (!groupedByPlace[tower][level][description]) {
              groupedByPlace[tower][level][description] = [];
            }
            groupedByPlace[tower][level][description].push(concreting);

            return groupedByPlace;
          },
          {}
        );
      }
      Object.entries(concretingsByPlace).map(
        ([level, concretingsByDescription], i) => {
          Object.entries(concretingsByDescription).map(
            ([description, concretings], j) => {
              if (Array.isArray(concretings)) {
                // Project without towers application
                let volume = 0;
                concretings.map((concreting) => {
                  concreting["trucks"].map((truck) => {
                    volume = volume + truck["volume"];
                  });
                });
                tableRows.push(
                  new TableRow({
                    children: [
                      ...(i === 0 && j === 0
                        ? [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [normalText(fck)],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              rowSpan: Object.values(concretingsByPlace)
                                .flatMap(Object.values)
                                .reduce((acc, arr) => acc + arr.length, 0),
                              verticalAlign: VerticalAlign.CENTER,
                            }),
                          ]
                        : []),
                      ...(j === 0
                        ? [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [normalText(level)],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              rowSpan: Object.keys(concretingsByDescription)
                                .length,
                              verticalAlign: VerticalAlign.CENTER,
                            }),
                          ]
                        : []),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [normalText(description)],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [normalText(volume.toString())],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                    ],
                  })
                );
              } else {
                // Project with towers application
                const tower = level;
                level = description;
                const concretingsByLevel = concretingsByDescription;
                concretingsByDescription = concretings;

                Object.entries(concretingsByDescription).map(
                  ([description, concretings], k) => {
                    let volume = 0;
                    concretings.map((concreting) => {
                      concreting["trucks"].map((truck) => {
                        volume = volume + truck["volume"];
                      });
                    });
                    tableRows.push(
                      new TableRow({
                        children: [
                          ...(i === 0 && j === 0 && k === 0
                            ? [
                                new TableCell({
                                  children: [
                                    new Paragraph({
                                      children: [normalText(fck)],
                                      alignment: AlignmentType.CENTER,
                                    }),
                                  ],
                                  rowSpan: Object.values(concretingsByPlace)
                                    .flatMap((level) => Object.values(level))
                                    .flatMap((description) =>
                                      Object.values(description)
                                    )
                                    .reduce((acc, arr) => acc + arr.length, 0),
                                  verticalAlign: VerticalAlign.CENTER,
                                }),
                              ]
                            : []),
                          ...(j === 0 && k === 0
                            ? [
                                new TableCell({
                                  children: [
                                    new Paragraph({
                                      children: [normalText(tower)],
                                      alignment: AlignmentType.CENTER,
                                    }),
                                  ],
                                  rowSpan: Object.values(concretingsByLevel)
                                    .flatMap(Object.values)
                                    .reduce((acc, arr) => acc + arr.length, 0),
                                  verticalAlign: VerticalAlign.CENTER,
                                }),
                              ]
                            : []),
                          ...(k === 0
                            ? [
                                new TableCell({
                                  children: [
                                    new Paragraph({
                                      children: [normalText(level)],
                                      alignment: AlignmentType.CENTER,
                                    }),
                                  ],
                                  rowSpan: Object.keys(concretingsByDescription)
                                    .length,
                                  verticalAlign: VerticalAlign.CENTER,
                                }),
                              ]
                            : []),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [normalText(description)],
                                alignment: AlignmentType.CENTER,
                              }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [normalText(volume.toString())],
                                alignment: AlignmentType.CENTER,
                              }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                          }),
                        ],
                      })
                    );
                  }
                );
              }
            }
          );
        }
      );
    });

    const table = new Table({
      rows: tableRows,
      width: {
        size: 100,
        type: "pct",
      },
    });

    return [...section, table];
  }

  let body = [];
  if (reportInputs === "Concretagem") {
    concretings.map((concreting) => {
      if (concreting["trucks"].length !== 0) {
        const testBodyExists = concreting["trucks"].some((truck) =>
          truck["test_bodies"].every((testBody) => testBody !== "-")
        );
        if (testBodyExists) {
          body.push({
            properties: {
              page: {
                size: {
                  orientation: PageOrientation.LANDSCAPE, // Define a orientação para paisagem
                },
                margin: {
                  top: 720, // Margem superior (em Twips, 720 Twips = 0.5 polegadas)
                  bottom: 720,
                  left: 720,
                  right: 720,
                },
              },
            },
            headers: {
              default: header,
            },
            children: concretingsPageBuilder(concreting),
            footers: {
              default: footer,
            },
          });
        }
      }
    });
  } else if (reportInputs === "Resultados abaixo do esperado") {
    body.push({
      properties: {
        page: {
          size: {
            orientation: PageOrientation.LANDSCAPE, // Define a orientação para paisagem
          },
          margin: {
            top: 720, // Margem superior (em Twips, 720 Twips = 0.5 polegadas)
            bottom: 720,
            left: 720,
            right: 720,
          },
        },
      },
      headers: {
        default: header,
      },
      children: resultsPageBuilder(concretings),
      footers: {
        default: footer,
      },
    });
  } else if (reportInputs === "Medição por Pavimento") {
    body.push({
      properties: {
        page: {
          size: {
            orientation: PageOrientation.LANDSCAPE, // Define a orientação para paisagem
          },
          margin: {
            top: 720, // Margem superior (em Twips, 720 Twips = 0.5 polegadas)
            bottom: 720,
            left: 720,
            right: 720,
          },
        },
      },
      headers: {
        default: header,
      },
      children: levelMensurationPageBuilder(concretings),
      footers: {
        default: footer,
      },
    });
  } else if (reportInputs === "Medição por Elemento") {
    body.push({
      properties: {
        page: {
          size: {
            orientation: PageOrientation.LANDSCAPE, // Define a orientação para paisagem
          },
          margin: {
            top: 720, // Margem superior (em Twips, 720 Twips = 0.5 polegadas)
            bottom: 720,
            left: 720,
            right: 720,
          },
        },
      },
      headers: {
        default: header,
      },
      children: descriptionMensurationPageBuilder(concretings),
      footers: {
        default: footer,
      },
    });
  } else if (reportInputs === "Medição por Elemento em Pavimento") {
    body.push({
      properties: {
        page: {
          size: {
            orientation: PageOrientation.LANDSCAPE, // Define a orientação para paisagem
          },
          margin: {
            top: 720, // Margem superior (em Twips, 720 Twips = 0.5 polegadas)
            bottom: 720,
            left: 720,
            right: 720,
          },
        },
      },
      headers: {
        default: header,
      },
      children: placeMensurationPageBuilder(concretings),
      footers: {
        default: footer,
      },
    });
  }

  if (body.length === 0) {
    // toast({
    //   title:
    //     "Relatório Vazio, verificar se existem concretagens e se os corpos de prova foram cadastrados",
    //   status: "error",
    //   isClosable: true,
    // });
    console.log(
      "Relatório Vazio, verificar se existem concretagens e se os corpos de prova foram cadastrados"
    );
  } else {
    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              font: "Arial",
              size: 22, // Tamanho 11 (em half-points, 22 half-points = 11 pts)
            },
            paragraph: {
              spacing: {
                after: 160, // 8 pt
              },
            },
          },
        },
      },
      sections: body,
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "relatorio-concretagem.docx");
    });
  }
}

export default concretingsReportBuilder;
