import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";

import aokiTemplate from "./memorial_aoki.docx";
import aokiTemplateResults from "./memorial_aoki_results.docx";
import decourtTemplate from "./memorial_decourt.docx";
import decourtTemplateResults from "./memorial_decourt_results.docx";

async function memorialWordBuilder(
  geotechnicsData,
  geotechnicsInputs,
  methodsData,
  pileLength,
  reportInputs
) {
  let template = null;

  const perimeter = (Math.PI * geotechnicsInputs["dimension_1"]).toFixed(3);
  const area = (
    Math.PI * Math.pow(geotechnicsInputs["dimension_1"] / 2, 2)
  ).toFixed(5);

  let data = {
    type: geotechnicsInputs["type"],
    dimension_1: geotechnicsInputs["dimension_1"],
    dimension_3: geotechnicsInputs["dimension_3"],
    investigation: [],
    parameters: [],
    perimeter: perimeter,
    results: [],
    area: area,
  };

  // ADICIONAR BREAKS PARA OTIMIZAR MAPS
  function aokiMemorial() {
    const pointLayer = parseInt(
      methodsData["CRITÉRIOS DE PROJETISTA"][0]["Valor"]
    );
    methodsData["FATORES DE CORREÇÃO"].map((parameter) => {
      if (geotechnicsInputs["type"] === parameter["Estaca"]) {
        data["f1"] = parameter["F1"];
        data["f2"] = parameter["F2"];
      }
    });

    geotechnicsData.map((layer, i) => {
      data["investigation"].push({
        elevation: layer["elevation"],
        soil: layer["soil"],
        nspt: layer["nspt"],
      });
      methodsData["PARÂMETROS DO SOLO"].map((parameter) => {
        if (layer["soil"] === parameter["Solo"]) {
          data["parameters"].push({
            elevation: layer["elevation"],
            soil: layer["soil"],
            k: parameter["K (MPa)"] * 1000,
            alfa: parameter["α (%)"],
          });
        }
      });

      if (reportInputs === "Memorial de Cálculo - Camada Única") {
        data["pile_length"] = pileLength;
        if (i + 1 <= pileLength) {
          data["results"].push({
            elevation: layer["elevation"],
            rl: layer["rl"],
          });
        }
        // Camada do comprimento da estaca
        if (i + 1 === pileLength) {
          data["rl_accumulated"] = geotechnicsData[i]["rl_accumulated"];
          data["rp"] = geotechnicsData[i]["rp"];
          data["ru"] = geotechnicsData[i]["ru"];
          data["pa"] = geotechnicsData[i]["pa"];
        }
        // Critério de Projetista - Profundidade da ponta
        if (i + 1 === pileLength + 1 + pointLayer) {
          data["np"] = layer["nspt"];
          data["length"] = i + 1;
        }
      } else if (reportInputs === "Memorial de Cálculo - Geral") {
        if (layer["pa"] !== "") {
          data["results"].push({
            elevation: layer["elevation"],
            rl: layer["rl"],
            rl_accumulated: layer["rl_accumulated"],
            rp: layer["rp"],
            ru: layer["ru"],
            pa: layer["pa"],
          });
        }
      }
    });
  }

  /// ADICIONAR BREAKS PARA OTIMIZAR MAPS
  function decourtMemorial() {
    let nsptPoint = [];
    let layersPoint = [];
    const nsptMean = methodsData["CRITÉRIOS DE PROJETISTA"][0]["Valor"];
    const lateralLength = methodsData["CRITÉRIOS DE PROJETISTA"][1]["Valor"];
    const lateralLimit = methodsData["CRITÉRIOS DE PROJETISTA"][2]["Valor"];

    geotechnicsData.map((layer, i) => {
      data["investigation"].push({
        elevation: layer["elevation"],
        soil: layer["soil"],
        nspt: layer["nspt"],
      });
      methodsData["PARÂMETROS DO SOLO"].map((parameter) => {
        if (layer["soil"] === parameter["Solo"]) {
          data["parameters"].push({
            elevation: layer["elevation"],
            soil: layer["soil"],
            c: parameter["C (kPa)"],
          });
        }
      });
      let soilType;
      methodsData["CONSIDERAÇÕES DO SOLO"].map((parameter) => {
        if (layer["soil"] === parameter["Solo"]) {
          Object.entries(parameter).map(([key, value]) => {
            if (value) {
              soilType = key;
            }
          });
        }
      });
      methodsData["FATORES DE CORREÇÃO (PONTA)"].map((parameter) => {
        if (geotechnicsInputs["type"] === parameter["Estaca"]) {
          data["parameters"][i]["alfa"] = parameter[soilType];
        }
      });
      methodsData["FATORES DE CORREÇÃO (LATERAL)"].map((parameter) => {
        if (geotechnicsInputs["type"] === parameter["Estaca"]) {
          data["parameters"][i]["beta"] = parameter[soilType];
        }
      });

      if (reportInputs === "Memorial de Cálculo - Camada Única") {
        data["pile_length"] = pileLength;
        if (i + 1 >= pileLength - 1 && i + 1 <= pileLength + 1) {
          nsptPoint.push(layer["nspt"]);
          layersPoint.push(i + 1);
        }
        // Critério de Projetista - Desconsiderar atrito lateral nas profundidades da ponta
        if (lateralLength && i + 1 <= pileLength - 2) {
          data["results"].push({
            elevation: layer["elevation"],
            rl: layer["rl"],
          });
        } else if (!lateralLength && i + 1 <= pileLength) {
          data["results"].push({
            elevation: layer["elevation"],
            rl: layer["rl"],
          });
        }
        // Última camada
        if (i + 1 === geotechnicsData.length) {
          // Limitadores do Nspt para Atrito Lateral
          let nsptMin = "";
          let nsptMax = "";
          methodsData["LIMITADORES DO NSPT PARA ATRITO LATERAL"].map(
            (parameter) => {
              if (geotechnicsInputs["type"] === parameter["Estaca"]) {
                nsptMin = parameter["Limite inferior (≥)"];
                nsptMax = parameter["Limite superior (≤)"];
              }
            }
          );
          data["nspt_min"] = nsptMin;
          data["nspt_max"] = nsptMax;

          // Critério de Projetista - Limitar valores de rp a 1,25 * rl para estaca escavada
          if (geotechnicsInputs["type"] === "Escavada" && lateralLimit) {
            data["lateralLimit"] = "≤ 1.25 * RL,camada";
          } else {
            data["lateralLimit"] = "";
          }

          // Critério de Projetista - Utilizar média de Nspt acima
          if (nsptMean) {
            data["nsptMean"] =
              "O valor de NL foi calculado utilizando a média de todos os Nspt até a camada analisada.";
          } else {
            data["nsptMean"] = "";
          }

          data["rl_accumulated"] =
            geotechnicsData[pileLength - 1]["rl_accumulated"];
          data["length"] = layersPoint;
          data["np"] = (
            nsptPoint.reduce((nsptSum, nspt) => nsptSum + Number(nspt), 0) /
            nsptPoint.length
          ).toFixed(2);
          data["rp"] = geotechnicsData[pileLength - 1]["rp"];
          data["ru"] = geotechnicsData[pileLength - 1]["ru"];
          data["pa"] = geotechnicsData[pileLength - 1]["pa"];
        }
      } else if (reportInputs === "Memorial de Cálculo - Geral") {
        if (layer["pa"] !== "") {
          data["results"].push({
            elevation: layer["elevation"],
            rl: layer["rl"],
            rl_accumulated: layer["rl_accumulated"],
            rp: layer["rp"],
            ru: layer["ru"],
            pa: layer["pa"],
          });
        }
      }
    });
  }

  if (methodsData["TIPO DO MÉTODO"] === "Aoki-Velloso") {
    aokiMemorial();
    if (reportInputs === "Memorial de Cálculo - Camada Única") {
      template = aokiTemplate;
    } else if (reportInputs === "Memorial de Cálculo - Geral") {
      template = aokiTemplateResults;
    }
  } else if (methodsData["TIPO DO MÉTODO"] === "Decourt-Quaresma") {
    decourtMemorial();
    if (reportInputs === "Memorial de Cálculo - Camada Única") {
      template = decourtTemplate;
    } else if (reportInputs === "Memorial de Cálculo - Geral") {
      template = decourtTemplateResults;
    }
  }

  fetch(template)
    .then((response) => response.arrayBuffer())
    .then((content) => {
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      // Inserir os dados no template
      doc.setData(data);
      try {
        // Renderizar o documento
        doc.render();
      } catch (error) {
        console.error("Erro ao renderizar o documento:", error);
        return;
      }
      // Exportar como arquivo Word
      const blob = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      saveAs(blob, "Memorial Geotécnico.docx");
    })
    .catch((error) => console.error("Erro ao carregar o template:", error));
}

export default memorialWordBuilder;
