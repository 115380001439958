import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Main.module.css";

import Sidebar from "../../components/Sidebar";

import { FaRegBuilding } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { FaTable } from "react-icons/fa";

import { Box, Flex, Text, List, ListItem, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { BsHouse } from "react-icons/bs";

import Model from "./pages/Model";
import Composing from "./pages/Composing";
import Filter from "./pages/Filter";
import Table from "./pages/Table";

import initialComposingData from "./utils/data/initialComposingData.json";

function Main({ userInputs }) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Modelo");
  const [ifcFileName, setIfcFileName] = useState("");
  const [ifcData, setIfcData] = useState("");
  const [composingInputs, setComposingInputs] = useState({
    selected_name: "Template_ARS",
    name_input: "",
    composing: initialComposingData,
  });
  const [composingsData, setComposingsData] = useState({
    Template_ARS: initialComposingData,
  });

  function updateComposingInputs(name, value) {
    setComposingInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  const handleNext = () => {
    const options = Object.keys(componentMap);
    const currentIndex = options.indexOf(selectedOption);
    const nextIndex = (currentIndex + 1) % options.length;
    setSelectedOption(options[nextIndex]);
  };

  const handlePrevious = () => {
    const options = Object.keys(componentMap);
    const currentIndex = options.indexOf(selectedOption);
    const previousIndex = (currentIndex - 1 + options.length) % options.length;
    setSelectedOption(options[previousIndex]);
  };

  const pages = {
    Modelo: {
      icon: <FaRegBuilding size="35px" />,
      action: () => setSelectedOption("Modelo"),
      enabled: true,
      condition: true,
      component: (
        <Model
          ifcFileName={ifcFileName}
          setIfcFileName={setIfcFileName}
          setIfcData={setIfcData}
          handleNext={handleNext}
        />
      ),
    },
    Composições: {
      icon: <FaClipboardList size="35px" />,
      action: () => setSelectedOption("Composições"),
      enabled: false,
      condition: ifcData !== "",
      component: (
        <Composing
          userInputs={userInputs}
          ifcData={ifcData}
          composingInputs={composingInputs}
          updateComposingInputs={updateComposingInputs}
          composingsData={composingsData}
          setComposingsData={setComposingsData}
        />
      ),
    },
    Tabela: {
      icon: <FaTable size="35px" />,
      action: () => setSelectedOption("Tabela"),
      enabled: false,
      condition: ifcData !== "",
      component: (
        <Table ifcData={ifcData} composing={composingInputs["composing"]} />
      ),
    },
  };

  const componentMap = {
    Modelo: (
      <Model
        ifcFileName={ifcFileName}
        setIfcFileName={setIfcFileName}
        setIfcData={setIfcData}
        handleNext={handleNext}
      />
    ),
    Composições: (
      <Composing
        userInputs={userInputs}
        ifcData={ifcData}
        composingInputs={composingInputs}
        updateComposingInputs={updateComposingInputs}
        composingsData={composingsData}
        setComposingsData={setComposingsData}
      />
    ),
    // Setorização: <Filter />,
    Tabela: (
      <Table ifcData={ifcData} composing={composingInputs["composing"]} />
    ),
  };

  return userInputs["id"] !== null ? (
    <div className={styles.bodyQuantitars}>
      <Sidebar
        product={"quantitars"}
        plan={"free"}
        pages={pages}
        selected={selectedOption}
      />
      {pages[selectedOption]["component"]}
    </div>
  ) : (
    navigate("/")
  );
}

export default Main;
