import styles from "./Support.module.css";
import support from "../utils/data/support.json";

import { Box, Flex, Text } from "@chakra-ui/react";

function Support({}) {
  return (
    <div className={styles.section}>
      <h1>Perguntas frequentes</h1>
      <p>Não encontrou a sua dúvida aqui ou possui alguma sugestão?</p>
      <p>
        Envie um email para:
        <strong> engenharssuporte@gmail.com</strong>
      </p>
      {Object.entries(support).map(([title, questions]) => {
        return (
          <>
            <h2>{title}</h2>
            {questions.map((question) => (
              <div className={styles.question}>
                <h1>{question["question"]}</h1>
                <p>R: {question["answer"]}</p>
              </div>
            ))}
          </>
        );
      })}
    </div>
  );
}

export default Support;
