import styles from "./Sidebar.module.css";
import ProductIcon from "./ProductIcon";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { CgLogOut } from "react-icons/cg";

function Navbar({ product, plan, pages, selected }) {
  const navigate = useNavigate();

  return (
    <div className={styles.navbar}>
      <div className={styles.header}>
        <ProductIcon product={product} plan={plan} />
      </div>
      <div className={styles.main}>
        {Object.entries(pages).map(([page, info], index) => (
          <Tooltip label={page} key={index} placement="right">
            <IconButton
              variant="icon"
              icon={info["icon"]}
              onClick={info["action"]}
              className={styles.button}
              color={
                page !== selected ? "var(--text-color)" : "var(--primary-color)"
              }
              isDisabled={
                info["enabled"] ? false : info["condition"] ? false : true
              }
            ></IconButton>
          </Tooltip>
        ))}
      </div>
      <div className={styles.footer}>
        <Tooltip label="Sair" placement="right">
          <IconButton
            variant="icon"
            icon={<CgLogOut size="30px" />}
            onClick={() => navigate("/")}
            color="red"
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default Navbar;
