import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

async function memorialExcelBuilder(projectData, solutionInputs) {
  const headers = [
    [
      "Tipo",
      "Nome",
      "Diâmetro (cm)",
      "Quantidade",
      "Rc (kN)",
      "Rt (kN)",
      "Comprimento Adotado",
      "Radm (kN)",
    ],
  ];

  const rows = projectData["solutions"][solutionInputs["selected_name"]].map(
    (solution, i) => [
      solution["type"],
      solution["name"],
      solution["parameters"]["diameter"],
      solution["parameters"]["quantity"],
      solution["parameters"]["rc"],
      solution["parameters"]["rt"],
      solution["parameters"]["length"],
      solution["parameters"]["radm"],
    ]
  );

  const sheetData = [...headers, ...rows];

  // Criar um novo workbook e uma planilha
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(sheetData);

  // Definir larguras variáveis para cada coluna
  // ws["!cols"] = [
  //   { wch: 8 }, // Cota
  //   { wch: 8 }, // Nspt
  //   { wch: 20 }, // Solo
  //   { wch: 16 }, // Resistência Lateral
  //   { wch: 26 }, // Resistência Lateral Acumulada
  //   { wch: 15 }, // Resistência Ponta
  //   { wch: 10 }, // C. Rup. (kN)
  //   { wch: 10 }, // C. Adm. (kN)
  // ];

  // Adicionar a planilha ao workbook
  XLSX.utils.book_append_sheet(wb, ws, solutionInputs["selected_name"]);

  // Gerar um Blob para download
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(blob, "Resumo de Fundações.xlsx");
}

export default memorialExcelBuilder;
