import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Editable,
  EditableInput,
  EditablePreview,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Select,
  Tab,
  TabList,
  Table,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { IconButton, Input, Tooltip } from "@chakra-ui/react";
import {
  CheckIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import { FaRegSave } from "react-icons/fa";

import styles from "../Main.module.css";

import parametersSettings from "../utils/data/parametersSettings.json";

import {
  settingsDuplicate,
  settingsEdit,
  settingsRemove,
  settingsSave,
} from "../utils/services/settings";

function Config({ userInputs, fundarsData, updateFundarsData }) {
  const toast = useToast();
  const [methodInputs, setMethodInputs] = useState({
    selected_name: "Aoki-Velloso",
    name_input: "",
    type: "estacas",
    parameters: parametersSettings["settings"][0]["parameters"],
  });
  const [formOpen, setFormOpen] = useState("");
  const [openIndexes, setOpenIndexes] = useState([0]);

  function updateMethodInputs(name, value) {
    setMethodInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onMethodFoundationClassChange(ev) {
    updateMethodInputs("type", ev.target.value);
    if (ev.target.value === "estacas") {
      parametersSettings["settings"].map((settings) => {
        if (settings["name"] === "Aoki-Velloso")
          updateMethodInputs("selected_name", settings["name"]);
        updateMethodInputs("parameters", settings["parameters"]);
      });
    } else if (ev.target.value === "estacas") {
      parametersSettings["settings"].map((settings) => {
        if (settings["name"] === "Bulbo de Tensões")
          updateMethodInputs("selected_name", settings["name"]);
        updateMethodInputs("parameters", settings["parameters"]);
      });
    }
  }

  function onMethodSelectedChange(ev) {
    const value = ev.target.value;
    updateMethodInputs("selected_name", value);
    fundarsData["settings"].map((settings) => {
      if (value === settings["name"]) {
        updateMethodInputs("parameters", settings["parameters"]);
      }
    });
    setOpenIndexes([0]);
  }

  function onMethodInputChange(ev) {
    const value = ev.target.value;
    updateMethodInputs("name_input", value);
  }

  function onMethodActions(action) {
    const options = {
      duplicate: async () => {
        if (methodInputs["name_input"] === "") {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          fundarsData["settings"].some(
            (settings) => settings["name"] === methodInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Um método com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando o método...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await settingsDuplicate([userInputs, methodInputs]);
            // Optimistic update
            const newValue = {
              name: methodInputs["name_input"],
              type: methodInputs["type"],
              parameters: methodInputs["parameters"],
            };
            updateFundarsData("settings", [
              ...fundarsData["settings"],
              newValue,
            ]);
            toast({
              title: "Método criado com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao criar o método, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
          updateMethodInputs("selected_name", methodInputs["name_input"]);
          setFormOpen("");
          updateMethodInputs("name_input", "");
        }
      },
      edit: async () => {
        if (methodInputs["name_input"] === "") {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          fundarsData["settings"].some(
            (settings) => settings["name"] === methodInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Um método com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando o método...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await settingsEdit([userInputs, methodInputs]);
            // Optimistic update
            const updatedFundarsDataSettings = fundarsData["settings"];
            updatedFundarsDataSettings.map((settings, i) => {
              if (methodInputs["selected_name"] === settings["name"]) {
                updatedFundarsDataSettings[i]["name"] =
                  methodInputs["name_input"];
              }
            });
            updateFundarsData("settings", [...updatedFundarsDataSettings]);
            toast({
              title: "Método editado com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao editar o método, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
          updateMethodInputs("selected_name", methodInputs["name_input"]);
          setFormOpen("");
          updateMethodInputs("name_input", "");
        }
      },
      remove: async () => {
        const toastId = toast({
          title: "Removendo o projeto...",
          description: "Aguarde enquanto os dados são processados.",
          status: "loading",
          duration: null,
        });

        try {
          await settingsRemove([userInputs, methodInputs]);
          // Optimistic update
          const updatedFundarsDataSettings = [...fundarsData["settings"]];
          updatedFundarsDataSettings.map((settings, i) => {
            if (methodInputs["selected_name"] === settings["name"]) {
              updatedFundarsDataSettings.splice(i, 1);
            }
          });
          updateFundarsData("settings", [...updatedFundarsDataSettings]);
          toast({
            title: "Método removido com sucesso",
            status: "success",
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Erro ao remover o método, tente novamente mais tarde",
            status: "error",
            isClosable: true,
          });
        } finally {
          toast.close(toastId);
        }
        setFormOpen("");
        updateMethodInputs(
          "selected_name",
          parametersSettings["settings"][0]["name"]
        );
        updateMethodInputs(
          "parameters",
          parametersSettings["settings"][0]["parameters"]
        );
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  async function onParameterInputChange(key, row, col, value) {
    setMethodInputs((prevInputs) => {
      const newState = { ...prevInputs };
      newState["parameters"][key][row][col] = parseFloat(value);
      return newState;
    });

    const toastId = toast({
      title: "Editando parâmetro...",
      description: "Aguarde enquanto os dados são processados.",
      status: "loading",
      duration: null,
    });

    try {
      await settingsSave([userInputs, methodInputs]);
      toast({
        title: "Parâmetro editado com sucesso",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro ao editar o parâmetro, tente novamente mais tarde",
        status: "error",
        isClosable: true,
      });
    } finally {
      toast.close(toastId);
    }
  }

  function onParameterCheckboxChange(key, row, col, value) {
    setMethodInputs((prevInputs) => {
      const newState = { ...prevInputs };
      const exclusiveKeys = ["Argilas", "Solos intermediários", "Areias"];
      if (exclusiveKeys.includes(col)) {
        // Define todos como false
        exclusiveKeys.forEach((category) => {
          newState["parameters"][key][row][category] = false;
        });

        // Define apenas o checkbox clicado como true
        newState["parameters"][key][row][col] = value;
      } else {
        // Se for qualquer outro parâmetro, apenas atualiza normalmente
        newState["parameters"][key][row][col] = value;
      }
      return newState;
    });
    settingsSave([userInputs, methodInputs])
      .then(() => {
        toast({
          title: "Parâmetro editado com sucesso",
          status: "success",
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao editar o parâmetro, tente novamente mais tarde",
          status: "error",
          isClosable: true,
        });
      });
  }

  return (
    <>
      <div className={styles.mainConfig}>
        <div className={styles.configMenu}>
          <Tabs
            onChange={onMethodFoundationClassChange}
            variant="soft-rounded"
            color="var(--background-dark-color)"
            textColor="var(--text-color)"
            value={methodInputs["type"]}
          >
            <TabList>
              <Tab
                checked={methodInputs["type"] === "estacas"}
                value={"estacas"}
              >
                Métodos de Cálculo Estacas
              </Tab>
              {/* <Tab checked={methodInputs["type"] === "sapatas"}>
                Métodos de Cálculo Sapatas
              </Tab> */}
            </TabList>
          </Tabs>
        </div>
        <div className={styles.configMenu}>
          <Select
            onChange={onMethodSelectedChange}
            variant="outline"
            w="300px"
            value={methodInputs["selected_name"]}
          >
            {fundarsData["settings"].map((settings) => {
              if (methodInputs["type"] === settings["type"]) {
                return (
                  <option key={settings["name"]}>{settings["name"]}</option>
                );
              }
            })}
          </Select>
          <Popover
            isOpen={formOpen === "duplicate"}
            onOpen={() => setFormOpen("duplicate")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                variant="icon"
                icon={
                  <Tooltip
                    hasArrow
                    label="Duplicar"
                    bg="gray"
                    color="black"
                    fontSize="md"
                  >
                    <CopyIcon />
                  </Tooltip>
                }
                isDisabled={
                  methodInputs["selected_name"] !== "Aoki-Velloso" &&
                  methodInputs["selected_name"] !== "Decourt-Quaresma" &&
                  methodInputs["selected_name"] !== "Bulbo de Tensões"
                }
              />
            </PopoverTrigger>
            <PopoverContent flexDirection={"row"}>
              <PopoverArrow backgroundColor={"black"} />
              <Input
                type="text"
                placeholder="Digite o nome do método"
                onChange={onMethodInputChange}
                value={methodInputs["name_input"]}
              />
              <IconButton
                variant="icon"
                icon={<CheckIcon />}
                onClick={() => onMethodActions("duplicate")}
              />
              <IconButton
                variant="icon"
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  setMethodInputs((prevInputs) => ({
                    ...prevInputs,
                    ["name_input"]: "",
                  }));
                }}
              />
            </PopoverContent>
          </Popover>
          <Popover
            isOpen={formOpen === "edit"}
            onOpen={() => setFormOpen("edit")}
            onClose={() => setFormOpen("")}
          >
            <PopoverTrigger>
              <IconButton
                variant="icon"
                icon={
                  <Tooltip
                    hasArrow
                    label="Renomear"
                    bg="gray"
                    color="black"
                    fontSize="md"
                  >
                    <EditIcon />
                  </Tooltip>
                }
                isDisabled={
                  methodInputs["selected_name"] === "Aoki-Velloso" ||
                  methodInputs["selected_name"] === "Decourt-Quaresma" ||
                  methodInputs["selected_name"] === "Bulbo de Tensões"
                }
              />
            </PopoverTrigger>
            <PopoverContent flexDirection={"row"}>
              <PopoverArrow backgroundColor={"black"} />
              <Input
                type="text"
                placeholder="Digite o nome do método"
                onChange={onMethodInputChange}
                value={methodInputs["name_input"]}
              />
              <IconButton
                variant="icon"
                icon={<CheckIcon />}
                onClick={() => onMethodActions("edit")}
              />
              <IconButton
                variant="icon"
                icon={<CloseIcon />}
                onClick={() => {
                  setFormOpen("");
                  setMethodInputs((prevInputs) => ({
                    ...prevInputs,
                    ["name_input"]: "",
                  }));
                }}
              />
            </PopoverContent>
          </Popover>
          <IconButton
            variant="icon"
            icon={
              <Tooltip
                hasArrow
                label="Remover"
                bg="gray"
                color="black"
                fontSize="md"
              >
                <DeleteIcon />
              </Tooltip>
            }
            onClick={() => {
              setFormOpen("remove");
            }}
            isDisabled={
              methodInputs["selected_name"] === "Aoki-Velloso" ||
              methodInputs["selected_name"] === "Decourt-Quaresma" ||
              methodInputs["selected_name"] === "Bulbo de Tensões"
            }
          />
        </div>
        <div className={styles.configSection}>
          <Accordion
            index={openIndexes}
            onChange={setOpenIndexes}
            allowMultiple
            w="100%"
          >
            {parametersSettings["settings"].map((settings) => {
              if (
                methodInputs["parameters"]["TIPO DO MÉTODO"] ===
                settings["name"]
              ) {
                return Object.entries(settings["parameters"]).map(
                  ([parameterName, parameterHeaders]) => (
                    <AccordionItem key={parameterName}>
                      {parameterName === "TIPO DO MÉTODO" ? (
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            <strong>
                              {parameterName}:{" "}
                              {methodInputs["parameters"][parameterName]}
                            </strong>
                          </Box>
                        </AccordionButton>
                      ) : (
                        <>
                          <AccordionButton>{parameterName}</AccordionButton>
                          <AccordionPanel>
                            <Table variant="simple">
                              <Thead>
                                <Tr>
                                  {Object.keys(parameterHeaders[0]).map(
                                    (header) => (
                                      <Th key={header}>{header}</Th>
                                    )
                                  )}
                                </Tr>
                              </Thead>
                              <Tbody>
                                {methodInputs["parameters"][parameterName].map(
                                  (parameter, i) => (
                                    <Tr key={i}>
                                      {methodInputs["selected_name"] ===
                                        "Aoki-Velloso" ||
                                      methodInputs["selected_name"] ===
                                        "Decourt-Quaresma" ||
                                      methodInputs["selected_name"] ==
                                        "Bulbo de Tensões"
                                        ? Object.values(parameter).map(
                                            (content, col_index) => (
                                              <Td key={col_index}>
                                                {content !== true &&
                                                content !== false ? (
                                                  <Box fontSize="md">
                                                    {content}
                                                  </Box>
                                                ) : (
                                                  <Checkbox
                                                    borderColor="var(--text-color)"
                                                    isChecked={content}
                                                    isDisabled={true}
                                                  ></Checkbox>
                                                )}
                                              </Td>
                                            )
                                          )
                                        : parametersSettings["settings"].map(
                                            (settings) => {
                                              if (
                                                methodInputs["parameters"][
                                                  "TIPO DO MÉTODO"
                                                ] === settings["name"]
                                              ) {
                                                return Object.keys(
                                                  settings["parameters"][
                                                    parameterName
                                                  ][0]
                                                ).map((key, col_index) => (
                                                  <Td key={col_index}>
                                                    {parameter[key] !== true &&
                                                    parameter[key] !== false &&
                                                    col_index !== 0 ? (
                                                      <Editable
                                                        defaultValue={
                                                          parameter[key] || "0"
                                                        }
                                                        fontSize="md"
                                                        onSubmit={(
                                                          newValue
                                                        ) => {
                                                          onParameterInputChange(
                                                            parameterName,
                                                            i,
                                                            key,
                                                            newValue
                                                          );
                                                        }}
                                                      >
                                                        <EditablePreview />
                                                        <EditableInput
                                                          textAlign="start"
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9.-]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                      </Editable>
                                                    ) : parameter[key] ===
                                                        true ||
                                                      parameter[key] ===
                                                        false ? (
                                                      <Checkbox
                                                        borderColor="var(--text-color)"
                                                        isChecked={
                                                          parameter[key]
                                                        }
                                                        onChange={(event) => {
                                                          onParameterCheckboxChange(
                                                            parameterName,
                                                            i,
                                                            key,
                                                            event.target.checked
                                                          );
                                                        }}
                                                        isDisabled={
                                                          [
                                                            "Argilas",
                                                            "Solos intermediários",
                                                            "Areias",
                                                          ].includes(
                                                            parameter[key]
                                                          ) && parameter[key]
                                                        }
                                                      ></Checkbox>
                                                    ) : (
                                                      <Box fontSize="md">
                                                        {parameter[key]}
                                                      </Box>
                                                    )}
                                                  </Td>
                                                ));
                                              }
                                            }
                                          )}
                                    </Tr>
                                  )
                                )}
                              </Tbody>
                            </Table>
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  )
                );
              }
            })}
          </Accordion>
        </div>
      </div>
      {formOpen === "remove" && methodInputs["selected_name"] !== "" && (
        <Modal isCentered isOpen={formOpen !== ""} onClose={""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Excluir {methodInputs["selected_name"]}</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormOpen("");
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button
                variant="delete"
                onClick={() => onMethodActions(formOpen)}
              >
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Config;
