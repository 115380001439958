import { useState } from "react";
import styles from "../../../Calculators.module.css";

import { BlockMath, InlineMath } from "react-katex";
import "katex/dist/katex.min.css";

import Select from "../../Select";
import InputNumber from "../../InputNumber";
import Output from "../../Output";

import {
  concreteBeamBending,
  concreteBeamShear,
} from "../../../../utils/services/calculators";
import { Text } from "@chakra-ui/react";

function Shear({}) {
  // SHEAR
  const [shearInputs, setShearInputs] = useState({
    fck: 20,
    fyk: 25,
    vd: 0,
    b: 0,
    h: 0,
    dl: 0,
    stress_type: 1,
    model: 1,
    teta: 45,
  });
  const [shearOutputs, setShearOutputs] = useState({
    vrd2: 0,
    vc: 0,
    vsw: 0,
    asw_min: 0,
    asw: 0,
    memorial: {},
  });

  function updateShearInputs(name, value) {
    setShearInputs((prevInputs) => ({
      ...prevInputs,
      [name]: parseFloat(value),
    }));
    setShearOutputs({
      vrd2: 0,
      vc: 0,
      vsw: 0,
      asw_min: 0,
      asw: 0,
    });
  }

  function onShearCalculate() {
    concreteBeamShear(shearInputs).then((response) => {
      setShearOutputs(response["data"]);
    });
  }

  return (
    <>
      <div className={styles.section} key={"header"}>
        <h1>Cortante para seção retangular</h1>
        <div className={styles.separator}></div>
        <div className={styles.calculator}>
          <div className={styles.inputs}>
            <Select
              text="fck"
              elements={[20, 25, 30, 35, 40, 45, 50]}
              unit="Mpa"
              onChange={(e) => updateShearInputs("fck", e.target.value)}
            />
            <Select
              text="fyk"
              elements={[25, 50, 60]}
              onChange={(e) => updateShearInputs("fyk", e.target.value)}
            />
            <InputNumber
              text="Vd"
              unit="tf"
              onChange={(e) => updateShearInputs("vd", e.target.value)}
            />
            <InputNumber
              text="b"
              unit="cm"
              onChange={(e) => updateShearInputs("b", e.target.value)}
            />
            <InputNumber
              text="h"
              unit="cm"
              onChange={(e) => updateShearInputs("h", e.target.value)}
            />
            <InputNumber
              text="dl"
              unit="cm"
              onChange={(e) => updateShearInputs("dl", e.target.value)}
            />
          </div>
          <div className={styles.buttons}>
            <button
              className={styles.calculateButton}
              onClick={onShearCalculate}
            >
              CALCULAR
            </button>
          </div>
          <div className={styles.outputs}>
            <Output
              text={shearInputs["vd"] + " <="}
              value={shearOutputs["vrd2"]}
              unit={"OK"}
            />
            <Output
              text={shearInputs["vd"] + " ="}
              value={shearOutputs["vc"] + " + " + shearOutputs["vsw"]}
            />
            <Output
              text="Asw,mín"
              value={shearOutputs["asw_min"]}
              unit="cm²/m"
            />
            <Output text="Asw" value={shearOutputs["asw"]} unit="cm²/m" />
          </div>
        </div>
      </div>
      <div className={styles.section} key={"memorial"}>
        <h1>
          <strong>Memorial de Cálculo:</strong>
        </h1>
        {shearOutputs["memorial"] &&
          Object.entries(shearOutputs["memorial"]).map(([className, steps]) => (
            <>
              <h2>{className}</h2>
              {steps.map((step) => (
                <>
                  <h3>{step["description"]}</h3>
                  <BlockMath math={step["formula"] + step["calculation"]} />
                </>
              ))}
            </>
          ))}
      </div>
    </>
  );
}

export default Shear;
