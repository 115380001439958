import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC9XFBvvPxOlwgj1CCybJhqdDgeuCJAVGQ",
  authDomain: "engenhars-f3aa3.firebaseapp.com",
  projectId: "engenhars-f3aa3",
  storageBucket: "engenhars-f3aa3.firebasestorage.app",
  messagingSenderId: "553841292986",
  appId: "1:553841292986:web:db4c0ffcc13cb64c4c3483",
  measurementId: "G-1N06GVQ59Q",
};

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();

// Inicializa o Analytics (somente se estiver rodando no navegador)
let analytics;
if (typeof window !== "undefined") {
  analytics = getAnalytics(app);
}

export { app, auth, analytics, logEvent };
