import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TbLogout, TbUserCircle } from "react-icons/tb";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { auth } from "../../firebase.js";

import styles from "./Main.module.css";

import calculators from "./utils/data/calculators.json";
import softwares from "./utils/data/softwares.json";
import tutorials from "./utils/data/tutorials.json";
import updates from "./utils/data/updates.json";

import homepageImage1 from "./utils/img/homepage-1.png";
import homepageImage2 from "./utils/img/homepage-2.png";

import logo from "./utils/img/ars-logo-sem-fundo.png";

import Calculators from "./pages/Calculators";
import Softwares from "./pages/Softwares";
import Tutorials from "./pages/Tutorials";
import Updates from "./pages/Updates";
import Support from "./pages/Support";
import Subscriptions from "./pages/Subscriptions";
import Login from "./pages/Login";
import User from "./pages/User";

import ColorModeButton from "./pages/components/ColorModeButton";

import { useToast } from "@chakra-ui/react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FaChevronDown } from "react-icons/fa";

// TEST
// const stripePromise = loadStripe(
//   "pk_test_51QwogSLbZvUnMrGq1DijD23ddx09SrHykqV4ZVmleQzRVyxnwiO2As3OvQstMpjP8g1N1Ipe2KjT4lO4MgjWHf1q00xCKmDjp4"
// );
// REAL
const stripePromise = loadStripe(
  "pk_live_51QwogSLbZvUnMrGqGDv2sK47uxv1h9QS2m77yQmGVpSl5fgk46RM1tYrhOL0Pi7JJzsNRCS8AIi6ZOTwEl7NwsUP00DfFSVybY"
);

function Main({ userInputs, updateUserInputs }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedTab, setSelectedTab] = useState("Home");
  const [menuOpen, setMenuOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState("");
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const popupRef = useRef(null);
  const [popupSelected, setPopupSelected] = useState("");
  const tutorialsRef = useRef(null);
  const updatesRef = useRef(null);
  const userRef = useRef(null);
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const urlParams = new URLSearchParams(window.location.search);
  const [resetPasswordToken, setResetPasswordToken] = useState("");

  function handleTabClick(tab) {
    if (menuOpen) {
      setMenuOpen(false);
    }
    setSelectedTab(tab);
  }

  function handleTabEnter(tab) {
    let buttonRef;
    if (tab === "Tutorials") buttonRef = tutorialsRef;
    else if (tab === "Updates") buttonRef = updatesRef;
    else if (tab === "User") buttonRef = userRef;
    const rect = buttonRef.current.getBoundingClientRect();
    setPopupPosition({
      top: rect.bottom,
      left: rect.left,
    });
    setPopupOpen((prev) => (prev === tab ? "" : tab));
  }

  function handlePopupClick(tab, option) {
    setPopupSelected(option);
    handleTabClick(tab);
  }

  function getSoftwareNavigate(software) {
    {
      /* Fixed because of database shutdown */
    }
    if (userInputs["id"] === null) {
      setSelectedTab("Login");
    } else {
      if (software === "FundArs") {
        let license = false;
        if (userInputs["licenses"]["fundars"]["plan"] !== "") {
          license = true;
        }
        if (license) {
          navigate(softwares[software]["navigate"]);
        } else {
          toast({
            title: "Você não possui uma licença para esse programa",
            description: "Para adquirir, vá para a aba de assinaturas",
            status: "warning",
            isClosable: true,
          });
        }
      } else if (software === "Complas") {
        let license = false;
        if (userInputs["licenses"]["complas"]["plan"] !== "") {
          license = true;
        }
        if (license) {
          navigate(softwares[software]["navigate"]);
        } else {
          toast({
            title: "Você não possui uma licença para esse programa",
            status: "warning",
            isClosable: true,
          });
        }
      } else {
        navigate(softwares[software]["navigate"]);
      }
    }
    // navigate(softwares[software]["navigate"]);
  }

  function logout() {
    auth.signOut().then(() => {
      updateUserInputs("id", null);
      updateUserInputs("name", "");
      updateUserInputs("email", "");
      updateUserInputs("licenses", {});
      setSelectedTab("Home");
      setPopupSelected("");
    });
  }

  return (
    <div className={`${menuOpen ? styles.menuOpenBodyHome : styles.bodyHome}`}>
      {/* Header */}
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          <div
            className={styles.logo}
            onClick={() => {
              handleTabClick("Home");
              setPopupSelected("");
            }}
          >
            <img className={styles.logoImage} src={logo}></img>
            <h1>
              Engenh<span>Ars</span>
            </h1>
          </div>
          <div className={`${menuOpen ? styles.tabsOpen : styles.tabs}`}>
            <button
              className={
                selectedTab === "Calculators"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={() => {
                handleTabClick("Calculators");
                setPopupSelected("");
              }}
            >
              Calculadoras
            </button>
            <button
              className={
                selectedTab === "Softwares"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={() => {
                handleTabClick("Softwares");
                setPopupSelected("");
              }}
            >
              Programas
            </button>
            <button
              ref={tutorialsRef}
              className={
                selectedTab === "Tutorials"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={() => handleTabEnter("Tutorials")}
            >
              Tutoriais
              <FaChevronDown
                className={
                  popupOpen === "Tutorials" ? styles.arrowOpen : styles.arrow
                }
              />
              {popupOpen === "Tutorials" && (
                <div
                  ref={popupRef}
                  className={styles.popup}
                  style={
                    window.innerWidth > 1000
                      ? { top: popupPosition.top, left: popupPosition.left }
                      : { top: popupPosition.top - 75 }
                  }
                >
                  <ul>
                    {Object.keys(tutorials).map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handlePopupClick("Tutorials", item)}
                        className={
                          selectedTab === "Tutorials" && popupSelected === item
                            ? styles.popupItemSelected
                            : styles.popupItem
                        }
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </button>
            <button
              ref={updatesRef}
              className={
                selectedTab === "Updates"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={() => handleTabEnter("Updates")}
            >
              Atualizações
              <FaChevronDown
                className={
                  popupOpen === "Updates" ? styles.arrowOpen : styles.arrow
                }
              />
              {popupOpen === "Updates" && (
                <div
                  ref={popupRef}
                  className={styles.popup}
                  style={
                    window.innerWidth > 1000
                      ? { top: popupPosition.top, left: popupPosition.left }
                      : { top: popupPosition.top - 75 }
                  }
                >
                  <ul>
                    {Object.keys(tutorials).map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handlePopupClick("Updates", item)}
                        className={
                          selectedTab === "Updates" && popupSelected === item
                            ? styles.popupItemSelected
                            : styles.popupItem
                        }
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </button>
            <button
              className={
                selectedTab === "Subscriptions"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={() => {
                handleTabClick("Subscriptions");
                setPopupSelected("");
              }}
            >
              Assinaturas
            </button>
            <button
              className={
                selectedTab === "Support"
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              onClick={() => {
                handleTabClick("Support");
                setPopupSelected("");
              }}
            >
              Perguntas Frequentes
            </button>
            {userInputs["id"] !== null ? (
              <div className={styles.mobileTabUser}>
                <TbUserCircle
                  onClick={() => handlePopupClick("User", "Info")}
                  color="var(--primary-color)"
                  size={30}
                />
                <span onClick={() => handlePopupClick("User", "Info")}>
                  {userInputs["name"]}
                </span>
                <TbLogout color="red" size={30} onClick={logout} />
              </div>
            ) : (
              <button
                className={styles.mobileTabLogin}
                onClick={() => {
                  handleTabClick("Login");
                }}
              >
                Entrar
              </button>
            )}
          </div>
        </div>
        <div className={styles.headerRight}>
          {/* Fixed because of database shutdown */}
          {userInputs["id"] !== null ? (
            <div
              ref={userRef}
              className={styles.tabUser}
              onClick={() => handleTabEnter("User")}
            >
              <TbUserCircle size={30} />
              <span>{userInputs["name"]}</span>
              <FaChevronDown
                className={
                  popupOpen === "User" ? styles.arrowOpen : styles.arrow
                }
              />
              {popupOpen === "User" && (
                <div
                  ref={popupRef}
                  className={styles.popup}
                  style={{ top: popupPosition.top, left: popupPosition.left }}
                >
                  <ul>
                    <li
                      onClick={() => handlePopupClick("User", "Info")}
                      className={
                        popupSelected === "Info"
                          ? styles.popupItemSelected
                          : styles.popupItem
                      }
                    >
                      Minha Conta
                    </li>
                    <li onClick={logout} className={styles.popupItem}>
                      Sair
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <button
              className={styles.tabLogin}
              onClick={(e) => {
                handleTabClick("Login");
                handleTabEnter(e, "");
              }}
            >
              Entrar
            </button>
          )}
          <button
            className={styles.mobileMenu}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? <CloseIcon /> : <HamburgerIcon />}
          </button>
          <ColorModeButton />
        </div>
      </header>
      {/* Section */}
      <main className={`${menuOpen ? styles.hideMain : styles.mainHome}`}>
        {selectedTab === "Home" && (
          <>
            <div
              className={styles.section}
              // style={{
              //   backgroundImage: `url(${backgroundImage})`,
              //   backgroundSize: "100% auto", // Estica a imagem para 100% da largura, mantendo a proporção
              //   backgroundPosition: "center", // Centraliza a imagem verticalmente
              //   backgroundRepeat: "no-repeat", // Evita que a imagem se repita
              // }}
            >
              <div className={styles.containerHeader}>
                <h1>Soluções para Engenharia Civil na palma da sua mão</h1>
                <p>
                  Sem precisar instalar nada utilize nossas ferramentas para
                  resolver problemas do seu dia a dia, confira alguns dos
                  benefícios das nossas ferramentas
                </p>
                <div className={styles.headerCards}>
                  <div className={styles.headerCard}>
                    Acesso online facilitado
                  </div>
                  <div className={styles.headerCard}>
                    Interface agradável e intuitiva
                  </div>
                  <div className={styles.headerCard}>Ferramentas modernas</div>
                  <div className={styles.headerCard}>
                    Constantemente incrementada
                  </div>
                  <div className={styles.headerCard}>
                    Exportação de tabelas e relatórios
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.section}
              style={{
                backgroundColor: "var(--background-dark-color)",
              }}
            >
              <div className={styles.containerCalculators}>
                <div className={styles.calculatorsText}>
                  <h1>Calculadoras</h1>
                  <p>
                    Faça verificações rápidas com uma interface simplificada e
                    direcionada para o seu problema
                  </p>
                  <button
                    className={styles.homepageButton}
                    onClick={() => handleTabClick("Calculators")}
                  >
                    SAIBA MAIS
                  </button>
                </div>
                <img
                  className={styles.calculatorsImage}
                  src={homepageImage1}
                ></img>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.containerSoftwares}>
                <div className={styles.softwaresText}>
                  <h1>Programas</h1>
                  <p>
                    Soluções completas para resolução de problemas maiores, com
                    integração do começo ao fim da solução
                  </p>
                  <button
                    className={styles.homepageButton}
                    onClick={() => handleTabClick("Softwares")}
                  >
                    SAIBA MAIS
                  </button>
                </div>
                <img
                  className={styles.softwaresImage}
                  src={homepageImage2}
                ></img>
              </div>
            </div>
            <div
              className={styles.section}
              style={{ backgroundColor: "var(--background-dark-color)" }}
            >
              <div className={styles.containerOthers}>
                <div className={styles.othersText}>
                  <h1>Guia básico</h1>
                  <p>Está com dificuldade em utilizar as nossas ferramentas?</p>
                  <p>
                    Pensando nisso desenvolvemos um tutorial completo para cada
                    ferramenta
                  </p>
                </div>
                <div className={styles.othersText}>
                  <h1>Transparência</h1>
                  <p>
                    Acompanhe o que está sendo desenvolvido pela nossa equipe
                    desde melhorias para as nossas ferramentas até novas
                    ferramentas que serão adicioandas!
                  </p>
                </div>
                <div className={styles.othersText}>
                  <h1>Participe do desenvovimento</h1>
                  <p>
                    Possui alguma sugestão de melhoria ou gostaria de reportar
                    algum erro que encontrou?
                  </p>
                  <p>Estamos abertos a todo e qualquer tipo de feedback</p>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedTab === "Calculators" && (
          <Calculators
            calculators={calculators}
            getSoftwareNavigate={getSoftwareNavigate}
          />
        )}
        {selectedTab === "Softwares" && (
          <Softwares
            softwares={softwares}
            getSoftwareNavigate={getSoftwareNavigate}
          />
        )}
        {selectedTab === "Tutorials" && (
          <Tutorials tutorials={tutorials} popupSelected={popupSelected} />
        )}
        {selectedTab === "Updates" && (
          <Updates updates={updates} popupSelected={popupSelected} />
        )}
        {selectedTab === "Support" && <Support />}
        {selectedTab === "Subscriptions" && (
          <Elements stripe={stripePromise}>
            <Subscriptions
              userInputs={userInputs}
              setSelectedTab={setSelectedTab}
            />
          </Elements>
        )}
        {selectedTab === "Login" && (
          <Login
            updateUserInputs={updateUserInputs}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === "Reset Password" && (
          <ResetPassword
            setSelectedTab={setSelectedTab}
            resetPasswordToken={resetPasswordToken}
          />
        )}
        {selectedTab === "User" && (
          <Elements stripe={stripePromise}>
            <User userInputs={userInputs} popupSelected={popupSelected} />
          </Elements>
        )}
      </main>
      {/* Footer */}
      <footer className={styles.footer}>
        <a
          // href="https://www.linkedin.com/in/felipe-dieguez-378473212/"
          target="_blank"
        >
          {/* <i className="fab fa-linkedin"></i> */}
        </a>
        <a href="https://www.instagram.com/engenhars_/" target="_blank">
          <i className="fab fa-instagram"></i>
        </a>
        <a
          // href="https://github.com/FelipeDieguez"
          target="_blank"
        >
          {/* <i className="fab fa-github"></i> */}
        </a>
      </footer>
    </div>
  );
}

export default Main;
