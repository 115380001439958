import React, { useState, useEffect } from "react";
import styles from "./Subscriptions.module.css";

import { api } from "../../../utils/services/api";

import { useStripe } from "@stripe/react-stripe-js";

import ProductIcon from "../../../components/ProductIcon";
import { Button } from "@chakra-ui/react";
import products from "../utils/data/products.json";

import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

const version = "real";

function Subscriptions({ userInputs, setSelectedTab }) {
  const stripe = useStripe();

  async function onSubscription(priceId) {
    if (!stripe) return;

    try {
      const response = await api.post("/subscription/checkout-session", [
        userInputs,
        priceId,
      ]);
      const sessionId = response.data.sessionId;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) console.error("Erro ao redirecionar:", error);
    } catch (err) {
      console.error("Erro ao iniciar sessão:", err);
    }
  }

  async function onManageSubscription(product, plan, clientPlan) {
    if (!stripe) return;
    try {
      let sessionUrl = null;
      if (product === "FundArs") {
        const response = await api.post(
          "/subscription/manage/update-fundars",
          userInputs
        );
        sessionUrl = response.data.sessionUrl;
      } else if (product === "Complas" && plan !== "premium") {
        const response = await api.post(
          "/subscription/manage/update-complas",
          userInputs
        );
        sessionUrl = response.data.sessionUrl;
      } else if (
        product === "Complas" &&
        plan === "premium" &&
        clientPlan === "premium"
      ) {
        const response = await api.post(
          "/subscription/manage/update-complas-quantity",
          userInputs
        );
        sessionUrl = response.data.sessionUrl;
      }

      if (sessionUrl) {
        window.location.href = sessionUrl; // Redireciona para o portal do Stripe
      } else {
        console.error("Erro: URL da sessão não recebida.");
      }
    } catch (err) {
      console.error("Erro ao iniciar sessão:", err);
    }
  }

  function onLogin() {
    setSelectedTab("Login");
  }

  return (
    <>
      <div className={styles.section}>
        {Object.entries(products[version]).map(([product, plans], i) => (
          <div className={styles.section} key={i}>
            <h1>{product} - Planos</h1>
            <div className={styles.products}>
              {Object.entries(plans).map(([planName, planInfo], j) => (
                <div className={styles.product} key={j}>
                  <div className={styles.productHeader}>
                    <ProductIcon
                      product={product.toLowerCase()}
                      plan={planName}
                    />
                    <h1>{planInfo["name"]}</h1>
                  </div>
                  <div className={styles.productContent}>
                    {planInfo["description"].map((description, k) => (
                      <div className={styles.productContentItem} key={k}>
                        {description["available"] ? (
                          <CheckIcon color="green.500" boxSize={4} />
                        ) : (
                          <CloseIcon color="red.500" boxSize={4} />
                        )}
                        <h1>{description["text"]}</h1>
                      </div>
                    ))}
                  </div>
                  <div className={styles.productFooter}>
                    <div className={styles.productFooterPrice}>
                      <h1>{planInfo["price"]}</h1>
                      <h2>
                        {product === "Complas" && planName === "premium"
                          ? "/mês por obra"
                          : "/mês"}
                      </h2>
                    </div>
                    {/* WITHOUT LOGIN */}
                    {userInputs["id"] === null ? (
                      <Button onClick={onLogin}>Assinar</Button>
                    ) : !userInputs["licenses"][product.toLocaleLowerCase()][
                        "plan"
                      ] ? (
                      // LOGIN WITHOUT LICENSE
                      <Button
                        onClick={() => onSubscription(planInfo["priceId"])}
                      >
                        Assinar
                      </Button>
                    ) : // LOGIN WITH LICENSE COMPLAS PREMIUM
                    planName === "premium" &&
                      userInputs["licenses"][product.toLocaleLowerCase()][
                        "plan"
                      ] === "premium" &&
                      product === "Complas" ? (
                      <Button
                        onClick={() =>
                          onManageSubscription(
                            product,
                            planName,
                            userInputs["licenses"][product.toLocaleLowerCase()][
                              "plan"
                            ]
                          )
                        }
                      >
                        Gerenciar Obras
                      </Button>
                    ) : // LOGIN WITH LICENSE
                    planInfo["available"] === false ||
                      userInputs["licenses"][product.toLocaleLowerCase()][
                        "plan"
                      ] === planName ||
                      userInputs["licenses"][product.toLocaleLowerCase()][
                        "plan"
                      ] === "tester" ? (
                      <Button
                        isDisabled={
                          planInfo["available"] === false ||
                          userInputs["licenses"][product.toLocaleLowerCase()][
                            "plan"
                          ] === planName ||
                          userInputs["licenses"][product.toLocaleLowerCase()][
                            "plan"
                          ] === "tester"
                        }
                      >
                        Já possui
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          onManageSubscription(
                            product,
                            planName,
                            userInputs["licenses"][product.toLocaleLowerCase()][
                              "plan"
                            ]
                          )
                        }
                      >
                        Trocar Plano
                      </Button>
                    )}
                    <div className={styles.productFooterPrice}>
                      <h2>(cancele quando quiser)</h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* <Button onClick={handleClick}>ASSINAR</Button> */}
    </>
  );
}

export default Subscriptions;
