import { useState, useEffect } from "react";

import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";

import styles from "../Main.module.css";

import { api } from "../../../utils/services/api";
import { workRegister, workEdit, workRemove } from "../utils/services/complas";

function Work({
  userInputs,
  setSelectedOption,
  workInputs,
  setWorkInputs,
  worksData,
  setWorksData,
}) {
  const toast = useToast();
  const [filteredWorks, setFilteredWorks] = useState([]);
  const [formOpen, setFormOpen] = useState("");
  const [number, setNumber] = useState("");
  const [levelInputs, setLevelInputs] = useState({
    name_input: "",
    selected_name: "",
  });
  const [towerInputs, setTowerInputs] = useState({
    name_input: "",
    selected_name: "",
  });

  function updateWorkInputs(key, value) {
    setWorkInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function updateLevelInputs(key, value) {
    setLevelInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function updateTowerInputs(key, value) {
    setTowerInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function onSearchInputChange(ev) {
    const searchTerm = ev.target.value;
    const filteredWorksData = [...worksData];
    const filter = filteredWorksData.filter((info) =>
      info["name"].toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredWorks(filter);
    updateWorkInputs("selected_name", "");
  }

  function onOpenWork(ev) {
    if (workInputs["selected_name"] !== "") {
      setSelectedOption("Lançamento");
    } else {
      toast({
        title: "Para executar essa função, selecione uma obra",
        status: "warning",
        isClosable: true,
      });
    }
  }

  function onWorkAction(action) {
    const options = {
      register: async () => {
        if (
          workInputs["name_input"] === "" ||
          workInputs["city"] === "" ||
          workInputs["test_bodies"].length === 0 ||
          workInputs["levels"].length === 0
        ) {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          worksData.some((info) => info["name"] === workInputs.name_input)
        ) {
          toast({
            title:
              "Uma obra com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando a obra...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await workRegister([userInputs, workInputs]);
            // Optimistic Update
            const updatedWorksData = [
              ...worksData,
              {
                name: workInputs["name_input"],
                city: workInputs["city"],
                test_bodies: workInputs["test_bodies"],
                levels: workInputs["levels"],
              },
            ];
            setWorkInputs({
              selected_name: "",
              name_input: "",
              city: "",
              test_bodies: [],
              levels: [],
              towers: [],
            });
            setWorksData(updatedWorksData);
            setFilteredWorks(updatedWorksData);
            toast({
              title: "Obra cadastrada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao cadastrar a obra, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
          setFormOpen("");
        }
      },
      edit: async () => {
        if (
          workInputs["name_input"] === "" ||
          workInputs["city"] === "" ||
          workInputs["test_bodies"].length === 0 ||
          workInputs["levels"].length === 0
        ) {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          workInputs["name_input"] !== workInputs["selected_name"] &&
          worksData.some((info) => info["name"] === workInputs.name_input)
        ) {
          toast({
            title:
              "Uma obra com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando a obra...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await workEdit([userInputs, workInputs]);
            // Optimistic Update
            const updatedWorksData = worksData.map((work) =>
              work.name === workInputs["selected_name"]
                ? {
                    ...work,
                    name: workInputs["name_input"],
                    city: workInputs["city"],
                    levels: workInputs["levels"],
                  }
                : work
            );
            setWorkInputs({
              selected_name: "",
              name_input: "",
              city: "",
              test_bodies: [],
              levels: [],
              towers: [],
            });
            setWorksData(updatedWorksData);
            setFilteredWorks(updatedWorksData);
            toast({
              title: "Obra editado com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao editar a obra, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
          updateWorkInputs("selected_name", "");
          setFormOpen("");
        }
      },
      remove: async () => {
        const toastId = toast({
          title: "Removendo a obra...",
          description: "Aguarde enquanto os dados são processados.",
          status: "loading",
          duration: null,
        });

        try {
          await workRemove([userInputs, workInputs]);
          // Optimistic Update
          const updatedWorksData = worksData.filter(
            (work) => work.name !== workInputs["selected_name"]
          );
          setWorkInputs({
            selected_name: "",
            name_input: "",
            city: "",
            test_bodies: [],
            levels: [],
            towers: [],
          });
          setWorksData(updatedWorksData);
          setFilteredWorks(updatedWorksData);
          toast({
            title: "Obra removida com sucesso",
            status: "success",
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Erro ao remover a obra, tente novamente mais tarde",
            status: "error",
            isClosable: true,
          });
        } finally {
          toast.close(toastId);
        }
        updateWorkInputs("selected_name", "");
        setFormOpen("");
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  function onLevelAction(action) {
    const options = {
      register: () => {
        if (levelInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome do pavimento",
            status: "warning",
            isClosable: true,
          });
        } else if (
          workInputs["levels"].some(
            (level) => level === levelInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Um pavimento com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          updateWorkInputs("levels", [
            ...workInputs["levels"],
            levelInputs["name_input"],
          ]);
          updateLevelInputs("name_input", "");
        }
      },
      edit: () => {
        if (levelInputs["selected_name"] === "") {
          toast({
            title: "Selecione um pavimento para editar",
            status: "warning",
            isClosable: true,
          });
        } else if (levelInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome do pavimento",
            status: "warning",
            isClosable: true,
          });
        } else if (
          workInputs["levels"].some(
            (level) => level === levelInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Um pavimento com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const updatedLevels = [...workInputs["levels"]];
          updatedLevels.map((level, i) => {
            if (levelInputs["selected_name"] === level) {
              updatedLevels[i] = levelInputs["name_input"];
            }
          });
          updateWorkInputs("levels", updatedLevels);
          updateLevelInputs("name_input", "");
          updateLevelInputs("selected_name", "");
        }
      },
      remove: () => {
        if (levelInputs["selected_name"] === "") {
          toast({
            title: "Selecione um pavimento para remover",
            status: "warning",
            isClosable: true,
          });
        } else {
          const updatedLevels = [...workInputs["levels"]];
          updatedLevels.map((level, i) => {
            if (levelInputs["selected_name"] === level) {
              updatedLevels.splice(i, 1);
            }
          });
          updateWorkInputs("levels", updatedLevels);
          updateLevelInputs("name_input", "");
          updateLevelInputs("selected_name", "");
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  function onTowerAction(action) {
    const options = {
      register: () => {
        if (towerInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da torre",
            status: "warning",
            isClosable: true,
          });
        } else if (
          workInputs["towers"].some(
            (tower) => tower === towerInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma torre com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          updateWorkInputs("towers", [
            ...workInputs["towers"],
            towerInputs["name_input"],
          ]);
          updateTowerInputs("name_input", "");
        }
      },
      edit: () => {
        if (towerInputs["selected_name"] === "") {
          toast({
            title: "Selecione uma torre para editar",
            status: "warning",
            isClosable: true,
          });
        } else if (towerInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da torre",
            status: "warning",
            isClosable: true,
          });
        } else if (
          workInputs["towers"].some(
            (tower) => tower === towerInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma torre com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const updatedTowers = [...workInputs["towers"]];
          updatedTowers.map((tower, i) => {
            if (towerInputs["selected_name"] === tower) {
              updatedTowers[i] = towerInputs["name_input"];
            }
          });
          updateWorkInputs("towers", updatedTowers);
          updateTowerInputs("name_input", "");
          updateTowerInputs("selected_name", "");
        }
      },
      remove: () => {
        if (towerInputs["selected_name"] === "") {
          toast({
            title: "Selecione uma torre para remover",
            status: "warning",
            isClosable: true,
          });
        } else {
          const updatedTowers = [...workInputs["towers"]];
          updatedTowers.map((tower, i) => {
            if (towerInputs["selected_name"] === tower) {
              updatedTowers.splice(i, 1);
            }
          });
          updateWorkInputs("towers", updatedTowers);
          updateTowerInputs("name_input", "");
          updateTowerInputs("selected_name", "");
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  useEffect(() => {
    if (formOpen !== "register" && formOpen !== "") {
      if (workInputs["selected_name"] === "") {
        toast({
          title: "Para executar essa função, selecione uma obra",
          status: "warning",
          isClosable: true,
        });
        setFormOpen("");
      }
    }
  }, [formOpen]);

  useEffect(() => {
    const fetchWorks = async () => {
      const toastId = toast({
        title: "Carregando obras...",
        status: "loading",
        duration: null,
      });

      try {
        const response = await api.post("/work", userInputs);
        setWorksData(response.data);
        setFilteredWorks(response.data);
      } catch (error) {
        toast({
          title: "Erro ao carregar as obras",
          description: "Por favor, tente novamente mais tarde.",
          status: "error",
          isClosable: true,
        });
      } finally {
        toast.close(toastId);
      }
    };

    fetchWorks();
  }, []);

  return (
    <>
      <div className={styles.mainWork}>
        <div className={styles.header}>
          <div className={styles.search}>
            <InputGroup>
              <InputLeftAddon children={<SearchIcon />} />
              <Input
                type="text"
                placeholder="Buscar projeto"
                onChange={onSearchInputChange}
              />
            </InputGroup>
          </div>
          <div className={styles.buttons}>
            <Tooltip label="Criar" placement="bottom">
              <IconButton
                variant="icon"
                icon={<AddIcon />}
                onClick={() => setFormOpen("register")}
              />
            </Tooltip>
            <Tooltip label="Editar" placement="bottom">
              <IconButton
                variant="icon"
                icon={<EditIcon />}
                onClick={() => {
                  worksData.map((work) => {
                    if (work["name"] === workInputs["selected_name"]) {
                      updateWorkInputs("name_input", work["name"]);
                      updateWorkInputs("city", work["city"]);
                      updateWorkInputs("test_bodies", work["test_bodies"]);
                      updateWorkInputs("levels", work["levels"]);
                      updateWorkInputs("towers", work["towers"]);
                    }
                  });
                  setFormOpen("edit");
                }}
              />
            </Tooltip>
            <Tooltip label="Remover" placement="bottom">
              <IconButton
                variant="icon"
                icon={<DeleteIcon />}
                onClick={() => setFormOpen("remove")}
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.section}>
          <Table>
            <Thead>
              <Tr>
                <Th>OBRAS</Th>
                {/* <Th>DIAS DOS CORPOS DE PROVA</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {filteredWorks.map((work, i) => {
                return (
                  <Tr
                    background={
                      workInputs["selected_name"] === work["name"]
                        ? "selected"
                        : "white"
                    }
                    aria-selected={
                      workInputs["selected_name"] === work["name"]
                        ? "true"
                        : "false"
                    } // Define se está selecionada
                    key={i}
                    onClick={() =>
                      updateWorkInputs("selected_name", work["name"])
                    }
                  >
                    <Td>{work["name"]}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
        <div className={styles.footer}>
          <Button
            // bg="var(--primary-color)"
            // color="var(--secondary-light-color)"
            onClick={onOpenWork}
          >
            Abrir Projeto
          </Button>
        </div>
      </div>
      {(formOpen === "register" ||
        (formOpen === "edit" && workInputs["selected_name"] !== "")) && (
        <Modal isCentered isOpen={formOpen !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Cadastro</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormOpen("");
                setWorkInputs({
                  selected_name: "",
                  name_input: "",
                  city: "",
                  test_bodies: [],
                  levels: [],
                  towers: [],
                });
                setNumber("");
                updateLevelInputs("name_input", "");
              }}
            />
            <ModalBody>
              <FormControl>
                <FormLabel>Nome da obra:</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  value={workInputs["name_input"]}
                  onChange={(ev) =>
                    updateWorkInputs("name_input", ev.target.value)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Cidade:</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  value={workInputs["city"]}
                  onChange={(ev) => updateWorkInputs("city", ev.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Adicionar Corpo de prova:</FormLabel>
                <FormControl display="flex" flexDirection="row">
                  <Input
                    size="sm"
                    type="text"
                    value={number}
                    onChange={(ev) => setNumber(ev.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    disabled={formOpen === "edit"}
                  />
                  <IconButton
                    variant="icon"
                    icon={<AddIcon />}
                    onClick={() => {
                      if (number === "") {
                        toast({
                          title: "Preencha o dia do corpo de prova",
                          status: "warning",
                          isClosable: true,
                        });
                      } else {
                        updateWorkInputs("test_bodies", [
                          ...workInputs["test_bodies"],
                          Number(number),
                        ]);
                        setNumber("");
                      }
                    }}
                    isDisabled={formOpen === "edit"}
                  />
                </FormControl>
                <div className={styles.modalTable}>
                  <Table variant="simple">
                    <Thead>
                      <Tr display="flex" w="100%" justifyContent="center">
                        <Th>Corpos de Prova</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        {workInputs["test_bodies"].length === 0 ? (
                          <Td>&nbsp;</Td>
                        ) : (
                          workInputs["test_bodies"].map((day, dayIndex) => (
                            <Td>{day}d</Td>
                          ))
                        )}
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </FormControl>
              <FormControl>
                <FormLabel>Gerenciar Pavimentos:</FormLabel>
                <FormControl display="flex" flexDirection="row">
                  <Input
                    size="sm"
                    type="text"
                    value={levelInputs["name_input"]}
                    onChange={(ev) =>
                      updateLevelInputs("name_input", ev.target.value)
                    }
                  />
                  <IconButton
                    variant="icon"
                    icon={<AddIcon />}
                    onClick={() => {
                      onLevelAction("register");
                    }}
                  />
                  <IconButton
                    variant="icon"
                    icon={<EditIcon />}
                    onClick={() => {
                      onLevelAction("edit");
                    }}
                  />
                  <IconButton
                    variant="icon"
                    icon={<DeleteIcon />}
                    onClick={() => {
                      onLevelAction("remove");
                    }}
                  />
                </FormControl>
                <div className={styles.modalTable}>
                  <Table variant="simple">
                    <Thead>
                      <Tr display="flex" w="100%" justifyContent="center">
                        <Th>Pavimentos</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {workInputs["levels"].map((level, levelIndex) => (
                        <Tr
                          aria-selected={
                            levelInputs["selected_name"] === level
                              ? "true"
                              : "false"
                          }
                        >
                          <Td
                            onClick={() =>
                              updateLevelInputs("selected_name", level)
                            }
                          >
                            {level}
                          </Td>
                        </Tr>
                      ))}
                      {Array.from({
                        length: Math.max(0, 4 - workInputs["levels"].length),
                      }).map((_, index) => (
                        <Tr key={`empty-${index}`}>
                          <Td>&nbsp;</Td> {/* Renderiza células vazias */}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </FormControl>
              <FormControl>
                <FormLabel>Gerenciar Setores: (opcional)</FormLabel>
                <FormControl display="flex" flexDirection="row">
                  <Input
                    size="sm"
                    type="text"
                    value={towerInputs["name_input"]}
                    onChange={(ev) =>
                      updateTowerInputs("name_input", ev.target.value)
                    }
                    disabled={
                      formOpen === "edit" && workInputs["towers"].length === 0
                    }
                  />
                  <IconButton
                    variant="icon"
                    icon={<AddIcon />}
                    onClick={() => {
                      onTowerAction("register");
                    }}
                    isDisabled={
                      formOpen === "edit" && workInputs["towers"].length === 0
                    }
                  />
                  <IconButton
                    variant="icon"
                    icon={<EditIcon />}
                    onClick={() => {
                      onTowerAction("edit");
                    }}
                    isDisabled={
                      formOpen === "edit" && workInputs["towers"].length === 0
                    }
                  />
                  <IconButton
                    variant="icon"
                    icon={<DeleteIcon />}
                    onClick={() => {
                      onTowerAction("remove");
                    }}
                    isDisabled={
                      formOpen === "edit" && workInputs["towers"].length === 0
                    }
                  />
                </FormControl>
                <div className={styles.modalTable}>
                  <Table variant="simple">
                    <Thead>
                      <Tr display="flex" w="100%" justifyContent="center">
                        <Th>Torres</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {workInputs["towers"].map((tower, towerIndex) => (
                        <Tr
                          aria-selected={
                            towerInputs["selected_name"] === tower
                              ? "true"
                              : "false"
                          }
                        >
                          <Td
                            onClick={() =>
                              updateTowerInputs("selected_name", tower)
                            }
                          >
                            {tower}
                          </Td>
                        </Tr>
                      ))}
                      {Array.from({
                        length: Math.max(0, 4 - workInputs["towers"].length),
                      }).map((_, index) => (
                        <Tr key={`empty-${index}`}>
                          <Td>&nbsp;</Td> {/* Renderiza células vazias */}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </FormControl>
            </ModalBody>
            <ModalFooter margin="0px">
              <Button onClick={() => onWorkAction(formOpen)}>SALVAR</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {formOpen === "remove" && workInputs["selected_name"] !== "" && (
        <Modal isCentered isOpen={formOpen !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Excluir a obra: <strong>{workInputs["selected_name"]}</strong>
            </ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormOpen("");
                setWorkInputs({
                  selected_name: "",
                  name_input: "",
                  city: "",
                  test_bodies: [],
                  levels: [],
                  towers: [],
                });
                setNumber("");
                updateLevelInputs("name_input", "");
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button variant="delete" onClick={() => onWorkAction(formOpen)}>
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Work;
