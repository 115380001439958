import styles from "./LicenseTag.module.css";

function LicenseTag({ plan, style }) {
  return (
    <span className={styles[plan]} style={style}>
      {plan}
    </span>
  );
}

export default LicenseTag;
