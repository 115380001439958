import { api } from "../../../../utils/services/api";

export function columnList(data) {
  return api.post("/column", data);
}

export function columnRegister(data) {
  return api.post("/column/register", data);
}

export function columnEdit(data) {
  return api.post("/column/edit", data);
}

export function columnRemove(data) {
  return api.post("/column/remove", data);
}

export function solutionList(data) {
  return api.post("/solution", data);
}

export function solutionRegister(data) {
  return api.post("/solution/register", data);
}

export function solutionEdit(data) {
  return api.post("/solution/edit", data);
}

export function solutionRemove(data) {
  return api.post("/solution/remove", data);
}

export function pileLengthCalculate(data) {
  return api.post("/solution/calculate/pilelength", data);
}

export function foundationList(data) {
  return api.post("/foundation", data);
}

export function foundationRegister(data) {
  return api.post("/foundation/register", data);
}

export function foundationEdit(data) {
  return api.post("/foundation/edit", data);
}

export function foundationRemove(data) {
  return api.post("/foundation/remove", data);
}
