import React from "react";
import ReactECharts from "echarts-for-react";
import { Box, Heading } from "@chakra-ui/react";

function GraphsCompare({ projectData }) {
  const solutions = {};
  Object.entries(projectData["solutions"]).map(([solution, foundations]) => {
    solutions[solution] = { quantities: 0, drilling: [], volume: [] };
    foundations.map((foundation) => {
      let quantity = foundation["parameters"]["quantity"];
      if (
        foundation["parameters"]["quantity"] === "3A" ||
        foundation["parameters"]["quantity"] === "3B"
      ) {
        quantity = 3;
      } else {
        quantity = Number(foundation["parameters"]["quantity"]);
      }

      solutions[solution]["quantities"] =
        solutions[solution]["quantities"] + quantity;
      solutions[solution]["drilling"].push(
        quantity * foundation["parameters"]["length"]
      );
      solutions[solution]["volume"].push(
        quantity *
          ((Math.PI * Math.pow(foundation["parameters"]["diameter"] / 100, 2)) /
            4) *
          foundation["parameters"]["length"]
      );
    });
  });

  // Opções do gráfico
  const quantities = {
    title: {
      text: "Quantidade de Furos",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
    },
    legend: { data: Object.keys(solutions), top: "bottom" },
    xAxis: { type: "category", data: ["Soluções"] },
    yAxis: { type: "value", name: "Quantidade" },
    series: Object.entries(solutions).map(([solutionName, values], index) => ({
      name: solutionName,
      type: "bar",
      data: Object.keys(solutions).map((key) => values["quantities"]), // Garante que cada série tenha valores no eixo correto
      itemStyle: { color: `hsl(${index * 60}, 70%, 50%)` },
    })),
  };

  const drilling = {
    title: {
      text: "Comprimento de Escavação",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
    },
    legend: { data: Object.keys(solutions), top: "bottom" },
    xAxis: { type: "category", data: ["Soluções"] },
    yAxis: { type: "value", name: "Comprimento (m)" },
    series: Object.entries(solutions).map(([solutionName, values], index) => ({
      name: solutionName,
      type: "bar",
      data: Object.keys(solutions).map((key) =>
        values["drilling"].reduce((acc, num) => acc + num, 0)
      ),
      itemStyle: { color: `hsl(${index * 60}, 70%, 50%)` },
    })),
  };

  const volume = {
    title: {
      text: "Volume de Concreto",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
    },
    legend: { data: Object.keys(solutions), top: "bottom" },
    xAxis: { type: "category", data: ["Soluções"] },
    yAxis: { type: "value", name: "Volume (m³)" },
    series: Object.entries(solutions).map(([solutionName, values], index) => ({
      name: solutionName,
      type: "bar",
      data: Object.keys(solutions).map((key) =>
        values["volume"].reduce((acc, num) => acc + num, 0).toFixed(2)
      ),
      itemStyle: { color: `hsl(${index * 60}, 70%, 50%)` },
    })),
  };

  return (
    <>
      <ReactECharts
        option={quantities}
        style={{ width: "400px", height: "250px" }}
      />
      <ReactECharts
        option={drilling}
        style={{ width: "400px", height: "250px" }}
      />
      <ReactECharts
        option={volume}
        style={{ width: "400px", height: "250px" }}
      />
    </>
  );
}

export default GraphsCompare;
