import { useState, useEffect } from "react";

import {
  Radio,
  RadioGroup,
  Stack,
  Select,
  Text,
  Input,
  Button,
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  AddIcon,
  EditIcon,
  DeleteIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";

import styles from "../Main.module.css";
import LicenseTag from "../../../components/LicenseTag";

import soilTypes from "../utils/data/soilTypes.json";
import foundationTypes from "../utils/data/foundationTypes.json";
import foundationGeometries from "../utils/data/foundationGeometries.json";
import geotechnicsHeaders from "../utils/data/geotechnicsHeaders.json";

import {
  layerList,
  layerRegister,
  layerEdit,
  layerRemove,
  investigationRegister,
  investigationEdit,
  investigationRemove,
  geotechnicsCalculate,
} from "../utils/services/geotechnics";

import { api } from "../../../utils/services/api";

import geotechnicsMemorialWordBuilder from "../utils/templates/geotechnicsMemorialWordBuilder";
import geotechnicsMemorialExcelBuilder from "../utils/templates/geotechnicsMemorialExcelBuilder";

function Geotechnics({
  userInputs,
  fundarsData,
  investigationInputs,
  updateInvestigationInputs,
  geotechnicsInputs,
  updateGeotechnicsInputs,
  projectData,
  updateProjectData,
  foundationClass,
  setFoundationClass,
  onClearGeotechnics,
}) {
  const toast = useToast();
  const [formOpen, setFormOpen] = useState("");

  // LAYERS MANAGE
  const [soilClass, setSoilClass] = useState("areia");
  const [layerInputs, setLayerInputs] = useState({
    elevation: "",
    soil: "Areia",
    nspt: "",
  });

  function onSoilClassChange(ev) {
    setSoilClass(ev);
    updateLayerInputs("soil", soilTypes[ev][0]);
  }

  function updateLayerInputs(name, value) {
    setLayerInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  }

  function onLayerActions(ev) {
    const action = ev.target.name;
    const options = {
      register: async () => {
        if (layerInputs["soil"] === "" || layerInputs["nspt"] === "") {
          toast({
            title: "Preencha todos os campos da camada",
            status: "warning",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando camada...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await layerRegister([
              userInputs,
              projectData["name"],
              investigationInputs,
              layerInputs,
            ]);
            //Optimistic Update
            const updatedInvestigationsData = projectData["investigations"];
            const updatedGeotechnicsData = [
              ...projectData["investigations"][
                investigationInputs["selected_name"]
              ],
            ];
            updatedGeotechnicsData.push({
              elevation: updatedGeotechnicsData.length + 1,
              soil: layerInputs["soil"],
              nspt: layerInputs["nspt"],
              rl: "",
              rl_accumulated: "",
              rp: "",
              ru: "",
              pa: "",
            });
            updatedInvestigationsData[investigationInputs["selected_name"]] =
              updatedGeotechnicsData;
            updateProjectData("investigations", updatedInvestigationsData);
            onClearGeotechnics();
            toast({
              title: "Camada de solo cadastrada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao cadastrar a camada, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      edit: async () => {
        if (layerInputs["elevation"] === "") {
          toast({
            title: "Para executar essa função, selecione uma camada",
            status: "warning",
            isClosable: true,
          });
        } else if (layerInputs["soil"] === "" || layerInputs["nspt"] === "") {
          toast({
            title: "Preencha todos os campos da sondagem",
            status: "warning",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando camada...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await layerEdit([
              userInputs,
              projectData["name"],
              investigationInputs,
              layerInputs,
            ]);
            //Optimistic Update
            const updatedInvestigationsData = projectData["investigations"];
            const updatedGeotechnicsData = [
              ...projectData["investigations"][
                investigationInputs["selected_name"]
              ],
            ];
            updatedGeotechnicsData[layerInputs["elevation"] - 1]["soil"] =
              layerInputs["soil"];
            updatedGeotechnicsData[layerInputs["elevation"] - 1]["nspt"] =
              layerInputs["nspt"];
            updatedInvestigationsData[investigationInputs["selected_name"]] =
              updatedGeotechnicsData;
            updateProjectData("investigations", updatedInvestigationsData);
            onClearGeotechnics();
            toast({
              title: "Camada de solo editada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title:
                "Erro ao editar camada de solo, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      remove: async () => {
        if (layerInputs["elevation"] === "") {
          toast({
            title: "Para executar essa função, selecione uma camada",
            status: "warning",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Removendo camada...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await layerRemove([
              userInputs,
              projectData["name"],
              investigationInputs,
              layerInputs,
            ]);
            //Optimistic Update
            const updatedInvestigationsData = projectData["investigations"];
            const updatedGeotechnicsData = [
              ...projectData["investigations"][
                investigationInputs["selected_name"]
              ],
            ];
            updatedGeotechnicsData.splice(layerInputs["elevation"] - 1, 1);
            updatedGeotechnicsData.map((layer, i) => {
              updatedGeotechnicsData[i]["elevation"] = i + 1;
            });
            updatedInvestigationsData[investigationInputs["selected_name"]] =
              updatedGeotechnicsData;
            updateProjectData("investigations", updatedInvestigationsData);
            onClearGeotechnics();
            toast({
              title: "Camada de solo removida com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title:
                "Erro ao remover camada de solo, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
    };
    updateLayerInputs("elevation", "");
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  // INVESTIGATIONS MANAGE

  function onInvestigationAction(action) {
    const options = {
      register: async () => {
        if (investigationInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da sondagem",
            status: "warning",
            isClosable: true,
          });
        } else if (
          Object.keys(projectData["investigations"]).some(
            (name) => name === investigationInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma sondagem com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando a sondagem...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await investigationRegister([
              userInputs,
              projectData["name"],
              investigationInputs,
            ]);
            //Optimistic Update
            const updatedInvestigationsData = projectData["investigations"];
            updatedInvestigationsData[investigationInputs["name_input"]] = [];
            updateProjectData("investigations", updatedInvestigationsData);
            setFormOpen("");
            updateInvestigationInputs(
              "selected_name",
              investigationInputs["name_input"]
            );
            updateInvestigationInputs("name_input", "");
            toast({
              title: "Sondagem cadastrada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao cadastrar sondagem, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      edit: async () => {
        if (investigationInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da sondagem",
            status: "warning",
            isClosable: true,
          });
        } else if (
          Object.keys(projectData["investigations"]).some(
            (name) => name === investigationInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma sondagem com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando a sondagem...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await investigationEdit([
              userInputs,
              projectData["name"],
              investigationInputs,
            ]);
            //Optimistic Update
            const updatedInvestigationsData = projectData["investigations"];
            updatedInvestigationsData[investigationInputs["name_input"]] =
              projectData["investigations"][
                investigationInputs["selected_name"]
              ];
            delete updatedInvestigationsData[
              investigationInputs["selected_name"]
            ];
            updateProjectData("investigations", updatedInvestigationsData);
            setFormOpen("");
            updateInvestigationInputs(
              "selected_name",
              investigationInputs["name_input"]
            );
            updateInvestigationInputs("name_input", "");
            toast({
              title: "Sondagem editada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao editar sondagem, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      remove: async () => {
        if (Object.keys(projectData["investigations"]).length === 1) {
          toast({
            title:
              "Essa é a única sondagem do projeto, para removê-la, apague o projeto",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Removendo a sondagem...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await investigationRemove([
              userInputs,
              projectData["name"],
              investigationInputs,
            ]);
            //Optimistic Update
            const updatedInvestigationsData = projectData["investigations"];
            delete updatedInvestigationsData[
              investigationInputs["selected_name"]
            ];
            updateProjectData("investigations", updatedInvestigationsData);
            setFormOpen("");
            updateInvestigationInputs(
              "selected_name",
              Object.keys(projectData["investigations"])[0]
            );
            toast({
              title: "Sondagem removida com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao remover sondagem, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  // GEOTECHNICS CALCULATE
  const [reportInputs, setReportInputs] = useState(
    "Memorial de Cálculo - Camada Única"
  );

  function onCalculate() {
    if (
      foundationClass === "sapatas" &&
      (geotechnicsInputs["dimension_1"] === "" ||
        geotechnicsInputs["dimension_2"] === "")
    ) {
      toast({
        title: "Preencha todas as informações",
        status: "warning",
        isClosable: true,
      });
    } else if (
      foundationClass !== "sapatas" &&
      geotechnicsInputs["dimension_1"] === ""
    ) {
      toast({
        title: "Preencha todas as informações",
        status: "warning",
        isClosable: true,
      });
    } else {
      const toastId = toast({
        title: "Calculando...",
        status: "loading",
        duration: null,
      });
      let method = {};
      fundarsData["settings"].map((settings) => {
        if (geotechnicsInputs["method"] === settings["name"]) {
          method = settings["parameters"];
        }
      });

      if (foundationClass === "tubuloes") {
        geotechnicsCalculate([
          projectData["investigations"][investigationInputs["selected_name"]],
          geotechnicsInputs,
          methodsData["estacas"][geotechnicsInputs["method"]],
        ])
          .then((response) => {
            const updatedInvestigationsData = projectData["investigations"];
            updatedInvestigationsData[investigationInputs["selected_name"]] =
              response["data"];
            updateProjectData("investigations", updatedInvestigationsData);
            toast({
              title: "Calculado com sucesso",
              status: "success",
              isClosable: true,
            });
          })
          .catch((error) => {
            toast({
              title: "Erro ao calcular",
              description: "Por favor, tente novamente mais tarde.",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            toast.close(toastId);
          });
      } else {
        geotechnicsCalculate([
          projectData["investigations"][investigationInputs["selected_name"]],
          geotechnicsInputs,
          method,
        ])
          .then((response) => {
            const updatedInvestigationsData = projectData["investigations"];
            updatedInvestigationsData[investigationInputs["selected_name"]] =
              response["data"];
            updateProjectData("investigations", updatedInvestigationsData);
            toast({
              title: "Calculado com sucesso",
              status: "success",
              isClosable: true,
            });
          })
          .catch((error) => {
            toast({
              title: "Erro ao calcular",
              description: "Por favor, tente novamente mais tarde.",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            toast.close(toastId);
          });
      }
    }
  }

  function onMemorial() {
    if (
      projectData["investigations"][investigationInputs["selected_name"]][0][
        "pa"
      ] === ""
    ) {
      toast({
        title: "Calcule primeiro para gerar o memorial",
        status: "warning",
        isClosable: true,
      });
    } else if (
      reportInputs === "Memorial de Cálculo - Camada Única" &&
      layerInputs["elevation"] === ""
    ) {
      toast({
        title: "Selecione um comprimento de estaca para gerar o memorial",
        status: "warning",
        isClosable: true,
      });
    } else {
      const toastId = toast({
        title: "Gerando memorial...",
        status: "loading",
        duration: null,
      });
      let method = {};
      fundarsData["settings"].map((settings) => {
        if (geotechnicsInputs["method"] === settings["name"]) {
          method = settings["parameters"];
        }
      });
      if (reportInputs !== "Resumo de Capacidades") {
        geotechnicsMemorialWordBuilder(
          projectData["investigations"][investigationInputs["selected_name"]],
          geotechnicsInputs,
          method,
          layerInputs["elevation"],
          reportInputs
        )
          .then((response) => {
            toast({
              title: "Memorial gerado com sucesso",
              status: "success",
              isClosable: true,
            });
          })
          .catch((error) => {
            toast({
              title: "Erro ao gerar memorial",
              description: "Por favor, tente novamente mais tarde.",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            toast.close(toastId);
          });
      } else {
        geotechnicsMemorialExcelBuilder(
          projectData["investigations"][investigationInputs["selected_name"]],
          geotechnicsInputs
        )
          .then((response) => {
            toast({
              title: "Tabela gerada com sucesso",
              status: "success",
              isClosable: true,
            });
          })
          .catch((error) => {
            toast({
              title: "Erro ao gerar tabela",
              description: "Por favor, tente novamente mais tarde.",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            toast.close(toastId);
          });
      }
    }
  }

  return (
    <>
      <div className={styles.mainGeotechnics}>
        <header className={styles.geotechnicsHeader}>
          <div className={styles.geotechnicsMenu}>
            <div className={styles.geotechnicsStep}>
              <Text>SONDAGEM:</Text>
            </div>
            <div className={styles.geotechnicsStep}>
              <RadioGroup onChange={onSoilClassChange} value={soilClass}>
                <Stack direction="row" spacing="65px">
                  <Radio
                    value="areia"
                    color="black"
                    colorScheme="blue"
                    fontSize="md"
                  >
                    Areia
                  </Radio>
                  <Radio
                    value="argila"
                    color="black"
                    colorScheme="blue"
                    fontSize="md"
                  >
                    Argila
                  </Radio>
                  <Radio
                    value="silte"
                    color="black"
                    colorScheme="blue"
                    fontSize="md"
                  >
                    Silte
                  </Radio>
                </Stack>
              </RadioGroup>
            </div>
            <div className={styles.geotechnicsStep}>
              <Text fontSize="md">Solo:</Text>
              <Select
                name="soil"
                onChange={(ev) => updateLayerInputs("soil", ev.target.value)}
                width="180px"
                size="xs"
                fontSize="md"
              >
                {soilTypes[soilClass].map((element) => (
                  <option key={element}>{element}</option>
                ))}
              </Select>
              <Text fontSize="md">Nspt=</Text>
              <Input
                name="nspt"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                  if (event.target.value.toString().length > 1) {
                    event.preventDefault();
                  }
                }}
                onChange={(ev) => updateLayerInputs("nspt", ev.target.value)}
                width="45px"
                size="xs"
                fontSize="md"
              />
            </div>
            <div className={styles.geotechnicsStep}>
              <Button
                name="register"
                width="100px"
                onClick={onLayerActions}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Cadastrar
              </Button>
              <Button
                name="edit"
                width="70px"
                onClick={onLayerActions}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Editar
              </Button>
              <Button
                name="remove"
                width="100px"
                onClick={onLayerActions}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Remover
              </Button>
            </div>
          </div>
          <div className={styles.geotechnicsMenu}>
            <div className={styles.geotechnicsStep}>
              <Text>TIPO DE FUNDAÇÃO</Text>
            </div>
            <div className={styles.geotechnicsStep}>
              <Select
                name="classe"
                onChange={(ev) => setFoundationClass(ev.target.value)}
                variant="outline"
                w="300px"
                size="xs"
                fontSize="md"
              >
                <option key="estacas" name="estacas">
                  Estacas
                </option>
                <option key="sapatas" name="sapatas" disabled={true}>
                  Sapatas
                </option>
                <option key="tubuloes" name="tubuloes" disabled={true}>
                  Tubulões
                </option>
              </Select>
            </div>
            <div className={styles.geotechnicsStep}>
              <Text>MÉTODOS</Text>
            </div>
            <Select
              name="method"
              onChange={(ev) =>
                updateGeotechnicsInputs("method", ev.target.value)
              }
              variant="outline"
              w="300px"
              size="xs"
              fontSize="md"
              value={geotechnicsInputs["method"]}
            >
              {fundarsData["settings"].map((settings) => {
                if (foundationClass === settings["type"]) {
                  return (
                    <option key={settings["name"]}>{settings["name"]}</option>
                  );
                }
              })}
            </Select>
          </div>
          <div className={styles.geotechnicsMenu}>
            <div className={styles.geotechnicsStep}>
              <Stack direction="row">
                <Text fontSize="md">Tipo:</Text>
                <Select
                  name="type"
                  onChange={(ev) =>
                    updateGeotechnicsInputs("type", ev.target.value)
                  }
                  width="200px"
                  size="xs"
                  fontSize="md"
                >
                  {foundationTypes[foundationClass].map((element) =>
                    element !== "Metálica" ? (
                      <option key={element}>{element}</option>
                    ) : (
                      <option key={element} disabled={true}>
                        {element}
                      </option>
                    )
                  )}
                </Select>
              </Stack>
            </div>
            <div className={styles.geotechnicsStep}>
              {foundationGeometries[geotechnicsInputs["type"]] ===
                "estaca circular" ||
              foundationGeometries[geotechnicsInputs["type"]] ===
                "sapata circular" ? (
                <Stack direction="row">
                  <Text fontSize="md">Diâmetro(m)=</Text>
                  <Input
                    name="dimension_1"
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(ev) =>
                      updateGeotechnicsInputs("dimension_1", ev.target.value)
                    }
                    width="50px"
                    size="xs"
                    fontSize="md"
                  />
                </Stack>
              ) : foundationGeometries[geotechnicsInputs["type"]] ===
                  "estaca retangular" ||
                foundationGeometries[geotechnicsInputs["type"]] ===
                  "sapata retangular" ? (
                <>
                  <Stack direction="row">
                    <Text fontSize="md">L(m)=</Text>
                    <Input
                      name="dimension_1"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimension_1", ev.target.value)
                      }
                      width="50px"
                      size="xs"
                      fontSize="md"
                    />
                  </Stack>
                  <Stack direction="row">
                    <Text fontSize="md">B(m)=</Text>
                    <Input
                      name="dimension_2"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimension_2", ev.target.value)
                      }
                      width="50px"
                      size="xs"
                      fontSize="md"
                    />
                  </Stack>
                </>
              ) : (
                <>
                  <Stack direction="row">
                    <Text fontSize="md">Df(m)=</Text>
                    <Input
                      name="dimension_1"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimension_1", ev.target.value)
                      }
                      width="50px"
                      size="xs"
                      fontSize="md"
                    />
                  </Stack>
                  <Stack direction="row">
                    <Text fontSize="md">Db(m)=</Text>
                    <Input
                      name="dimension_2"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimension_2", ev.target.value)
                      }
                      width="50px"
                      size="xs"
                      fontSize="md"
                    />
                  </Stack>
                </>
              )}
            </div>
            <div className={styles.geotechnicsStep}>
              {foundationClass === "sapatas" ? (
                <>
                  <Stack direction="row">
                    <Text fontSize="md">Prof.+</Text>
                    <Select
                      name="dimension_3"
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimension_3", ev.target.value)
                      }
                      width="65px"
                      size="xs"
                      fontSize="md"
                    >
                      {[0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9].map(
                        (element) => (
                          <option key={element}>{element}</option>
                        )
                      )}
                    </Select>
                  </Stack>
                  <Stack direction="row">
                    <Text fontSize="md">N.A.(m)=</Text>
                    <Select
                      name="dimension_4"
                      onChange={(ev) =>
                        updateGeotechnicsInputs("dimension_4", ev.target.value)
                      }
                      width="55px"
                      size="xs"
                      fontSize="md"
                    >
                      {[
                        ...Array(
                          projectData["investigations"][
                            investigationInputs["selected_name"]
                          ].length + 2
                        ).keys(),
                      ].map((element) => (
                        <option key={element}>{element}</option>
                      ))}
                    </Select>
                  </Stack>
                </>
              ) : (
                <Stack direction="row">
                  <Text fontSize="md">C.A.(m)=</Text>
                  <Select
                    name="dimension_3"
                    onChange={(ev) =>
                      updateGeotechnicsInputs("dimension_3", ev.target.value)
                    }
                    width="60px"
                    size="xs"
                    fontSize="md"
                    value={geotechnicsInputs["dimension_3"]}
                  >
                    {projectData["investigations"][
                      investigationInputs["selected_name"]
                    ]
                      ? [
                          ...Array(
                            projectData["investigations"][
                              investigationInputs["selected_name"]
                            ].length
                          ).keys(),
                        ].map((element) => (
                          <option key={element}>{element}</option>
                        ))
                      : null}
                  </Select>
                </Stack>
              )}
            </div>
            <div className={styles.geotechnicsStep}>
              <Button
                name="calculate"
                width="240px"
                onClick={onCalculate}
                colorScheme="blue"
                size="sm"
                fontSize="md"
              >
                Calcular
              </Button>
            </div>
          </div>
        </header>
        <nav>
          {/* <>
          <Tabs size="sm" variant="enclosed">
            <TabList>
              <Tab
                name="compressao"
                onClick={onGeotechnicsStressChange}
                fontSize="md"
              >
                Compressão
              </Tab>
              <Tab
                name="tracao"
                onClick={onGeotechnicsStressChange}
                _selected={{ color: "white", bg: "blue" }}
                fontSize="md"
              >
                Tração
              </Tab>
            </TabList>
          </Tabs>
        </> */}
        </nav>
        <div className={styles.geotechnicsSection}>
          <Table variant="simple">
            <Thead>
              <Tr>
                {geotechnicsHeaders[foundationClass]["headers"].map((h, i) => (
                  <Th key={"th" + i}>{h}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {projectData["investigations"][
                investigationInputs["selected_name"]
              ] &&
              projectData["investigations"][
                investigationInputs["selected_name"]
              ].length !== 0 ? (
                projectData["investigations"][
                  investigationInputs["selected_name"]
                ].map((layer, i) => (
                  <Tr
                    aria-selected={
                      layerInputs["elevation"] === i + 1 ? "true" : "false"
                    }
                    key={"row-" + i}
                  >
                    {geotechnicsHeaders[foundationClass]["values"].map(
                      (h, _i) => {
                        return (
                          layer[h] !== undefined && (
                            <Td
                              key={"col-" + _i}
                              onClick={() =>
                                updateLayerInputs("elevation", i + 1)
                              }
                            >
                              {h === "elevation"
                                ? layer[h] - 1 + "-" + layer[h]
                                : layer[h]}
                            </Td>
                          )
                        );
                      }
                    )}
                  </Tr>
                ))
              ) : (
                <Tr key={"row-0"}>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </div>
        <footer className={styles.geotechnicsFooter}>
          <div className={styles.geotechnicsFooterElement}>
            <Select
              w="200px"
              onChange={(ev) => {
                updateInvestigationInputs("selected_name", ev.target.value);
                updateLayerInputs("elevation", "");
              }}
              value={investigationInputs["selected_name"]}
            >
              {Object.keys(projectData["investigations"])
                .sort((a, b) => a.localeCompare(b))
                .map((investigationName, i) => (
                  <option key={i} value={investigationName}>
                    {" "}
                    {investigationName}{" "}
                  </option>
                ))}
            </Select>
            <Popover
              placement="bottom"
              isOpen={formOpen === "register"}
              onOpen={() => setFormOpen("register")}
              onClose={() => setFormOpen("")}
            >
              <PopoverTrigger>
                <IconButton
                  variant="icon"
                  icon={
                    <Tooltip
                      hasArrow
                      label="Criar"
                      bg="gray"
                      color="black"
                      fontSize="md"
                    >
                      <AddIcon />
                    </Tooltip>
                  }
                />
              </PopoverTrigger>
              <PopoverContent flexDirection={"row"}>
                <PopoverArrow backgroundColor={"black"} />
                <Input
                  type="text"
                  placeholder="Digite o nome da sondagem"
                  onChange={(ev) =>
                    updateInvestigationInputs("name_input", ev.target.value)
                  }
                  value={investigationInputs["name_input"]}
                />
                <IconButton
                  variant="icon"
                  icon={<CheckIcon />}
                  onClick={() => onInvestigationAction(formOpen)}
                />
                <IconButton
                  variant="icon"
                  icon={<CloseIcon />}
                  onClick={() => {
                    setFormOpen("");
                    updateInvestigationInputs("name_input", "");
                  }}
                />
              </PopoverContent>
            </Popover>
            <Popover
              placement="bottom"
              isOpen={formOpen === "edit"}
              onOpen={() => setFormOpen("edit")}
              onClose={() => setFormOpen("")}
            >
              <PopoverTrigger>
                <IconButton
                  variant="icon"
                  icon={
                    <Tooltip
                      hasArrow
                      label="Editar"
                      bg="gray"
                      color="black"
                      fontSize="md"
                    >
                      <EditIcon />
                    </Tooltip>
                  }
                />
              </PopoverTrigger>
              <PopoverContent flexDirection={"row"}>
                <PopoverArrow backgroundColor={"black"} />
                <Input
                  type="text"
                  placeholder="Digite o nome da sondagem"
                  onChange={(ev) =>
                    updateInvestigationInputs("name_input", ev.target.value)
                  }
                  value={investigationInputs["name_input"]}
                />
                <IconButton
                  variant="icon"
                  icon={<CheckIcon />}
                  onClick={() => onInvestigationAction(formOpen)}
                />
                <IconButton
                  variant="icon"
                  icon={<CloseIcon />}
                  onClick={() => {
                    setFormOpen("");
                    updateInvestigationInputs("name_input", "");
                  }}
                />
              </PopoverContent>
            </Popover>
            <IconButton
              variant="icon"
              icon={
                <Tooltip
                  hasArrow
                  label="Remover"
                  bg="gray"
                  color="black"
                  fontSize="md"
                >
                  <DeleteIcon />
                </Tooltip>
              }
              onClick={() => setFormOpen("remove")}
            />
          </div>
          <div className={styles.geotechnicsFooterElement}>
            <Tooltip
              placement="left"
              isDisabled={
                userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                userInputs["licenses"]["fundars"]["plan"] === "tester"
              }
              label={
                <span>
                  Para usar essa função habilite a licença{" "}
                  <LicenseTag plan="pro" /> ou uma superior
                </span>
              }
            >
              <Button
                isDisabled={
                  userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                  userInputs["licenses"]["fundars"]["plan"] !== "premium" &&
                  userInputs["licenses"]["fundars"]["plan"] !== "tester"
                }
                onClick={() => setFormOpen("export")}
              >
                Exportar
              </Button>
            </Tooltip>
          </div>
        </footer>
      </div>
      {formOpen === "remove" && investigationInputs["selected_name"] !== "" && (
        <Modal isCentered isOpen={formOpen !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Excluir {investigationInputs["selected_name"]}
            </ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormOpen("");
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button
                variant="delete"
                onClick={() => onInvestigationAction(formOpen)}
              >
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {formOpen === "export" && (
        <Modal isCentered isOpen={formOpen !== ""} onClose={""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Gerar Relatório</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormOpen("");
                setReportInputs("Memorial de Cálculo - Camada Única");
              }}
            />
            <ModalBody>
              <FormControl>
                <FormLabel>Tipo de Relatório à exportar:</FormLabel>
                <Select
                  value={reportInputs}
                  onChange={(ev) => setReportInputs(ev.target.value)}
                >
                  <optgroup label={"Word"}>
                    <option key={"Memorial de Cálculo - Camada Única"}>
                      {"Memorial de Cálculo - Camada Única"}
                    </option>
                    <option key={"Memorial de Cálculo - Geral"}>
                      {"Memorial de Cálculo - Geral"}
                    </option>
                  </optgroup>
                  <optgroup label={"Excel"}>
                    <option key={"Resumo de Capacidades"}>
                      {"Resumo de Capacidades"}
                    </option>
                  </optgroup>
                </Select>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onMemorial}>BAIXAR</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Geotechnics;
