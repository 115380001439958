import { useEffect, useState } from "react";
import styles from "../../../Calculators.module.css";
import { analytics, logEvent } from "../../../../../../firebase.js";

import { BlockMath, InlineMath } from "react-katex";
import "katex/dist/katex.min.css";

import Select from "../../Select";
import InputNumber from "../../InputNumber";
import Output from "../../Output";

import {
  concreteBeamBending,
  concreteBeamShear,
} from "../../../../utils/services/calculators";
import { Text } from "@chakra-ui/react";

function Bending({}) {
  // BENDING
  const [bendingInputs, setBendingInputs] = useState({
    fck: 20,
    fyk: 50,
    md: 0,
    b: 0,
    h: 0,
    dl: 0,
  });
  const [bendingOutputs, setBendingOutputs] = useState({
    ln: 0,
    domain: 0,
    ast_min: 0,
    ast: 0,
    asc: 0,
    memorial: {},
  });

  function updateBendingInputs(name, value) {
    setBendingInputs((prevInputs) => ({
      ...prevInputs,
      [name]: parseFloat(value),
    }));
    setBendingOutputs({
      ln: 0,
      domain: 0,
      ast_min: 0,
      ast: 0,
      asc: 0,
    });
  }

  function onBendingCalculate() {
    concreteBeamBending(bendingInputs).then((response) => {
      setBendingOutputs(response["data"]);
      logEvent(analytics, "button_click", {
        button_name: "bending-calculator-button",
      });
    });
  }

  useEffect(() => {
    logEvent(analytics, "page_view", { page: "bending-calculator" });
  }, []);

  return (
    <>
      <div className={styles.section} key={"header"}>
        <h1>Flexão simples:</h1>
        <div className={styles.calculator}>
          <div className={styles.inputs}>
            {/* <Select
              text="Resultado desejado"
              elements={["Área de aço", "Momento resistente"]}
              unit=""
              // onChange={(e) => updateBendingInputs("fck", e.target.value)}
            />
            <Select
              text="Seção"
              elements={["Retangular"]}
              unit=""
              // onChange={(e) => updateBendingInputs("fck", e.target.value)}
            /> */}
            <Select
              text="fck"
              elements={[
                20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
              ]}
              unit="Mpa"
              onChange={(e) => updateBendingInputs("fck", e.target.value)}
            />
            <Select
              text="fyk"
              elements={[50]}
              onChange={(e) => updateBendingInputs("fyk", e.target.value)}
            />
            <InputNumber
              text="Md"
              unit="tfm"
              onChange={(e) => updateBendingInputs("md", e.target.value)}
            />
            <InputNumber
              text="b"
              unit="cm"
              onChange={(e) => updateBendingInputs("b", e.target.value)}
            />
            <InputNumber
              text="h"
              unit="cm"
              onChange={(e) => updateBendingInputs("h", e.target.value)}
            />
            <InputNumber
              text="dl"
              unit="cm"
              onChange={(e) => updateBendingInputs("dl", e.target.value)}
            />
          </div>
          <div className={styles.buttons}>
            <button
              className={styles.calculateButton}
              onClick={onBendingCalculate}
            >
              CALCULAR
            </button>
          </div>
          <div className={styles.outputs}>
            <Text>{bendingOutputs["ln_text"] + bendingOutputs["ln"]}</Text>
            <Output text="Domínio" value={bendingOutputs["domain"]} />
            <Output
              text="As,mín"
              value={bendingOutputs["ast_min"]}
              unit="cm²"
            />
            <Output text="As" value={bendingOutputs["ast"]} unit="cm²" />
            <Output text="As'" value={bendingOutputs["asc"]} unit="cm²" />
          </div>
        </div>
      </div>
      <div className={styles.section} key={"memorial"}>
        <h1>Memorial de Cálculo:</h1>
        {bendingOutputs["memorial"] &&
          Object.entries(bendingOutputs["memorial"]).map(
            ([className, subclasses]) => (
              <>
                <h2>{className}</h2>
                {Object.entries(subclasses).map(([subclassName, steps]) => (
                  <>
                    <h3>{subclassName}</h3>
                    {steps.map((step) => (
                      <>
                        <h4>{step["description"]}</h4>
                        <BlockMath
                          math={step["formula"] + step["calculation"]}
                        />
                      </>
                    ))}
                  </>
                ))}
              </>
            )
          )}
      </div>
    </>
  );
}

export default Bending;
