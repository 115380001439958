import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import styles from "./Main.module.css";

import { api } from "../../utils/services/api";

import Sidebar from "../../components/Sidebar";

import { ReactComponent as GeotechnicsIcon } from "./utils/img/drilling-rig.svg";
import { ReactComponent as StructureIcon } from "./utils/img/building.svg";

import { FaRegBuilding } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";

import Project from "./pages/Project";
import Geotechnics from "./pages/Geotechnics";
import Solution from "./pages/Solution";
import Config from "./pages/Config";

import parametersSettings from "./utils/data/parametersSettings.json";
import dataGeotechnics from "./utils/data/dataGeotechnics.json";
import { useToast } from "@chakra-ui/react";

const initialGeotechnicsInputs = {
  method: "Aoki-Velloso",
  type: "Escavada",
  stress: "compressao",
  dimension_1: "",
  dimension_2: "",
  dimension_3: "0",
  dimension_4: "0",
};

function Main({ userInputs }) {
  const toast = useToast();

  // PROJECTS AND SETTINGS
  const [projectInputs, setProjectInputs] = useState({
    selected_name: "",
    name_input: "",
  });
  const [fundarsData, setFundarsData] = useState({
    projects: [],
    settings: [...parametersSettings["settings"]],
  });
  const [filteredProjects, setFilteredProjects] = useState([]);

  function updateFundarsData(key, value) {
    setFundarsData((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function updateProjectInputs(key, value) {
    setProjectInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  useEffect(() => {
    const fetchFundars = async () => {
      const toastId = toast({
        title: "Carregando projetos...",
        status: "loading",
        duration: null,
      });
      api
        .post("/project", userInputs)
        .then((response) => {
          const projects = [];
          response["data"].map((project) => {
            projects.push(project["name"]);
          });
          updateFundarsData("projects", projects);
          setFilteredProjects(projects);
        })
        .catch((error) => {
          toast({
            title: "Erro ao carregar os projetos",
            description: "Por favor, tente novamente mais tarde.",
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          toast.close(toastId);
        });
      api
        .post("/settings", userInputs)
        .then((response) => {
          if (response["data"].length > 0) {
            const all_settings = [
              ...parametersSettings["settings"],
              ...response["data"],
            ];
            updateFundarsData("settings", all_settings);
          }
        })
        .catch((error) => {
          toast({
            title: "Erro ao carregar as configurações",
            description: "Por favor, tente novamente mais tarde.",
            status: "error",
            isClosable: true,
          });
        });
    };
    fetchFundars();
  }, []);

  // GEOTECHNICS AND SOLUTION
  const [investigationInputs, setInvestigationInputs] = useState({
    selected_name: "",
    name_input: "",
  });
  const [geotechnicsInputs, setGeotechnicsInputs] = useState(
    initialGeotechnicsInputs
  );
  const [columnInputs, setColumnInputs] = useState({
    selected_name: "",
    name: "",
    fz: 0,
    mx: 0,
    my: 0,
  });
  const [solutionInputs, setSolutionInputs] = useState({
    selected_name: "",
    name_input: "",
  });
  const [projectData, setProjectData] = useState({
    name: "",
    investigations: [],
    columns: [],
    solutions: [],
  });
  const [foundationClass, setFoundationClass] = useState("estacas");

  function updateInvestigationInputs(key, value) {
    setInvestigationInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function onClearGeotechnicsInputs() {
    setGeotechnicsInputs(initialGeotechnicsInputs);
  }

  function onClearGeotechnics() {
    projectData["investigations"][investigationInputs["selected_name"]].map(
      (layer, i) => {
        layer["rl"] = "";
        layer["rl_accumulated"] = "";
        layer["rp"] = "";
        layer["ru"] = "";
        layer["pa"] = "";
      }
    );
  }

  function updateGeotechnicsInputs(key, value) {
    setGeotechnicsInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
    onClearGeotechnics();
  }

  function updateColumnInputs(name, value) {
    setColumnInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  }

  function updateSolutionInputs(name, value) {
    setSolutionInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  }

  function updateProjectData(key, value) {
    setProjectData((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  useEffect(() => {
    if (projectInputs["selected_name"] !== "") {
      const fetchProject = async () => {
        const toastId = toast({
          title: "Carregando projeto...",
          status: "loading",
          duration: null,
        });
        api
          .post("/investigation", [userInputs, projectData["name"]])
          .then((response) => {
            const investigations = {};
            response["data"].map((investigation) => {
              api
                .post("/layer", [
                  userInputs,
                  projectData["name"],
                  investigation["name"],
                ])
                .then((res) => {
                  const data = [];
                  res["data"].map((layer, _) => {
                    data.push(
                      Object.assign(
                        {
                          elevation: layer["elevation"],
                          soil: layer["soil"],
                          nspt: layer["nspt"],
                        },
                        dataGeotechnics[foundationClass][0]
                      )
                    );
                  });
                  investigations[investigation["name"]] = data;
                  updateProjectData("investigations", investigations);
                  updateInvestigationInputs(
                    "selected_name",
                    response["data"][0]["name"]
                  );
                })
                .catch((error) => {
                  toast({
                    title: "Erro ao carregar projeto",
                    description: "Por favor, tente novamente mais tarde.",
                    status: "error",
                    isClosable: true,
                  });
                })
                .finally(() => {
                  toast.close(toastId);
                });
            });
          });
        api
          .post("/column", [userInputs, projectData["name"]])
          .then((response) => {
            updateProjectData("columns", response["data"]);
            if (response["data"].length > 0) {
              updateColumnInputs("selected_name", response["data"][0]["name"]);
              updateColumnInputs("fz", response["data"][0]["fz"]);
              updateColumnInputs("mx", response["data"][0]["mx"]);
              updateColumnInputs("my", response["data"][0]["my"]);
            }
          })
          .catch((error) => {
            toast({
              title: "Erro ao carregar os pilares do projeto",
              description: "Por favor, tente novamente mais tarde.",
              status: "error",
              isClosable: true,
            });
          });
        api
          .post("/solution", [userInputs, projectData["name"]])
          .then((response) => {
            const solutions = {};
            response["data"].map((solution) => {
              api
                .post("/foundation", [
                  userInputs,
                  projectData["name"],
                  solution["name"],
                ])
                .then((res) => {
                  const data = [];
                  res["data"].map((foundation, _) => {
                    data.push({
                      type: foundation["type"],
                      name: foundation["name"],
                      parameters: foundation["parameters"],
                    });
                  });
                  solutions[solution["name"]] = data;
                  updateProjectData("solutions", solutions);
                  updateSolutionInputs(
                    "selected_name",
                    response["data"][0]["name"]
                  );
                })
                .catch((error) => {
                  toast({
                    title: "Erro ao carregar as soluções do projeto",
                    description: "Por favor, tente novamente mais tarde.",
                    status: "error",
                    isClosable: true,
                  });
                });
            });
          });
      };
      fetchProject();
    }
  }, [projectData["name"]]);

  // MAIN
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Projetos");
  const pages = {
    Projetos: {
      icon: <FaRegBuilding size="35px" />,
      action: () => {
        updateProjectInputs("selected_name", "");
        updateInvestigationInputs("selected_name", "");
        setProjectData({
          name: "",
          investigations: [],
          columns: [],
          solutions: [],
        });
        setSelectedOption("Projetos");
      },
      enabled: true,
      condition: true,
      component: (
        <Project
          userInputs={userInputs}
          projectInputs={projectInputs}
          updateProjectInputs={updateProjectInputs}
          fundarsData={fundarsData}
          updateFundarsData={updateFundarsData}
          filteredProjects={filteredProjects}
          setFilteredProjects={setFilteredProjects}
          updateProjectData={updateProjectData}
          setSelectedOption={setSelectedOption}
          onClearGeotechnicsInputs={onClearGeotechnicsInputs}
        />
      ),
    },
    Geotecnia: {
      icon: <GeotechnicsIcon width="40px" />,
      action: () => {
        onClearGeotechnics();
        updateGeotechnicsInputs("type", "Escavada");
        setSelectedOption("Geotecnia");
      },
      enabled: false,
      condition: selectedOption === "Geotecnia" || selectedOption === "Solução",
      component: (
        <Geotechnics
          userInputs={userInputs}
          fundarsData={fundarsData}
          investigationInputs={investigationInputs}
          updateInvestigationInputs={updateInvestigationInputs}
          geotechnicsInputs={geotechnicsInputs}
          updateGeotechnicsInputs={updateGeotechnicsInputs}
          projectData={projectData}
          updateProjectData={updateProjectData}
          foundationClass={foundationClass}
          setFoundationClass={setFoundationClass}
          onClearGeotechnics={onClearGeotechnics}
        />
      ),
    },
    Solução: {
      icon: <StructureIcon width="40px" />,
      action: () => setSelectedOption("Solução"),
      enable: false,
      condition:
        (selectedOption === "Geotecnia" &&
          projectData["investigations"][investigationInputs["selected_name"]] &&
          projectData["investigations"][investigationInputs["selected_name"]]
            .length > 0 &&
          projectData["investigations"][
            investigationInputs["selected_name"]
          ][0]["pa"] !== "") ||
        selectedOption === "Solução",
      component: (
        <Solution
          userInputs={userInputs}
          projectInputs={projectInputs}
          investigationInputs={investigationInputs}
          geotechnicsInputs={geotechnicsInputs}
          projectData={projectData}
          updateProjectData={updateProjectData}
          foundationClass={foundationClass}
          columnInputs={columnInputs}
          updateColumnInputs={updateColumnInputs}
          solutionInputs={solutionInputs}
          updateSolutionInputs={updateSolutionInputs}
        />
      ),
    },
    Configurações: {
      icon: <IoIosSettings size="35px" />,
      action: () => setSelectedOption("Configurações"),
      enable: true,
      condition: true,
      component: (
        <Config
          userInputs={userInputs}
          fundarsData={fundarsData}
          updateFundarsData={updateFundarsData}
        />
      ),
    },
  };

  return userInputs["id"] !== null ? (
    <div className={styles.bodyFundars}>
      <Sidebar
        product={"fundars"}
        plan={userInputs["licenses"]["fundars"]["plan"]}
        pages={pages}
        selected={selectedOption}
      />
      {pages[selectedOption]["component"]}
    </div>
  ) : (
    navigate("/")
  );
}

export default Main;
