import { useState, useEffect } from "react";

import styles from "../Main.module.css";
import LicenseTag from "../../../components/LicenseTag";

import {
  Button,
  Select,
  Input,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  ModalFooter,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import {
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@chakra-ui/react";
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";

import solutionHeaders from "../utils/data/solutionHeaders.json";
import pileCapTypes from "../utils/data/pileCapTypes.json";

import {
  columnRegister,
  columnEdit,
  columnRemove,
} from "../utils/services/solutions";

import {
  solutionRegister,
  solutionEdit,
  solutionRemove,
  pileLengthCalculate,
} from "../utils/services/solutions";

import {
  foundationRegister,
  foundationEdit,
  foundationRemove,
} from "../utils/services/solutions";

import solutionMemorialExcelBuilder from "../utils/templates/solutionMemorialExcelBuilder";

import GraphsCompare from "./components/solution/GraphsCompare";

function Structure({
  userInputs,
  projectInputs,
  investigationInputs,
  geotechnicsInputs,
  projectData,
  updateProjectData,
  foundationClass,
  columnInputs,
  updateColumnInputs,
  solutionInputs,
  updateSolutionInputs,
}) {
  const toast = useToast();

  // COLUMNS MANAGER
  const [formColumn, setFormColumn] = useState(false);

  function onColumnAction(action) {
    const options = {
      register: async () => {
        if (
          columnInputs["name"] === "" ||
          columnInputs["fz"] === "" ||
          columnInputs["mx"] === "" ||
          columnInputs["my"] === ""
        ) {
          toast({
            title: "Preencha todos os campos do pilar",
            status: "warning",
            isClosable: true,
          });
        } else if (
          projectData["columns"].some(
            (column) => column["name"] === columnInputs["name"]
          )
        ) {
          toast({
            title:
              "Um pilar com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando pilar...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await columnRegister([
              userInputs,
              projectData["name"],
              columnInputs,
            ]);
            const updatedColumnsData = [...projectData["columns"]];
            updatedColumnsData.push({
              name: columnInputs["name"],
              fz: columnInputs["fz"],
              mx: columnInputs["mx"],
              my: columnInputs["my"],
            });
            updateProjectData("columns", updatedColumnsData);
            updateColumnInputs("selected_name", "");
            updateColumnInputs("name", "");
            updateColumnInputs("fz", "");
            updateColumnInputs("mx", "");
            updateColumnInputs("my", "");
            toast({
              title: "Pilar cadastrado com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao cadastrar pilar, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      edit: async () => {
        if (columnInputs["selected_name"] === "") {
          toast({
            title: "Para executar essa função, selecione um pilar",
            status: "warning",
            isClosable: true,
          });
        } else if (
          columnInputs["name"] === "" ||
          columnInputs["fz"] === "" ||
          columnInputs["mx"] === "" ||
          columnInputs["my"] === ""
        ) {
          toast({
            title: "Preencha todos os campos do pilar",
            status: "warning",
            isClosable: true,
          });
        } else if (
          columnInputs["name"] !== columnInputs["selected_name"] &&
          projectData["columns"].some(
            (column) => column["name"] === columnInputs["name"]
          )
        ) {
          toast({
            title:
              "Um pilar com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando pilar...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await columnEdit([userInputs, projectData["name"], columnInputs]);
            const updatedColumnsData = [...projectData["columns"]];
            updatedColumnsData.map((column, i) => {
              if (columnInputs["selected_name"] === column["name"]) {
                updatedColumnsData[i] = {
                  name: columnInputs["name"],
                  fz: columnInputs["fz"],
                  mx: columnInputs["mx"],
                  my: columnInputs["my"],
                };
              }
            });
            updateProjectData("columns", updatedColumnsData);
            updateColumnInputs("selected_name", "");
            updateColumnInputs("name", "");
            updateColumnInputs("fz", "");
            updateColumnInputs("mx", "");
            updateColumnInputs("my", "");
            toast({
              title: "Pilar editado com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao editar pilar, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      remove: async () => {
        if (columnInputs["selected_name"] === "") {
          toast({
            title: "Para executar essa função, selecione um pilar",
            status: "warning",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Removendo pilar...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await columnRemove([userInputs, projectData["name"], columnInputs]);
            const updatedColumnsData = [...projectData["columns"]];
            updatedColumnsData.map((column, i) => {
              if (columnInputs["selected_name"] === column["name"]) {
                updatedColumnsData.splice(i, 1);
              }
            });
            updateProjectData("columns", updatedColumnsData);
            updateColumnInputs("selected_name", "");
            toast({
              title: "Pilar removido com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao remover pilar, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  // SOLUTIONS MANAGER
  const [formSolution, setFormSolution] = useState("");

  function onSolutionAction(action) {
    const options = {
      register: async () => {
        if (solutionInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da solução",
            status: "warning",
            isClosable: true,
          });
        } else if (
          Object.keys(projectData["solutions"]).some(
            (solution) => solution === solutionInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma solução com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando solução...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await solutionRegister([
              userInputs,
              projectData["name"],
              solutionInputs,
            ]);
            //Optimistic Update
            const updatedSolutionsData = projectData["solutions"];
            updatedSolutionsData[solutionInputs["name_input"]] = [];
            updateProjectData("solutions", updatedSolutionsData);
            setFormSolution("");
            updateSolutionInputs("selected_name", solutionInputs["name_input"]);
            updateSolutionInputs("name_input", "");
            toast({
              title: "Solução cadastrada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao cadastrar solução, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      edit: async () => {
        if (solutionInputs["name_input"] === "") {
          toast({
            title: "Preencha o nome da solução",
            status: "warning",
            isClosable: true,
          });
        } else if (
          Object.keys(projectData["solutions"]).some(
            (solution) => solution === solutionInputs["name_input"]
          )
        ) {
          toast({
            title:
              "Uma solução com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando solução...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await solutionEdit([
              userInputs,
              projectData["name"],
              solutionInputs,
            ]);
            //Optimistic Update
            const updatedSolutionsData = projectData["solutions"];
            updatedSolutionsData[solutionInputs["name_input"]] =
              projectData["solutions"][solutionInputs["selected_name"]];
            delete updatedSolutionsData[solutionInputs["selected_name"]];
            updateProjectData("solutions", updatedSolutionsData);
            setFormSolution("");
            updateSolutionInputs("selected_name", solutionInputs["name_input"]);
            updateSolutionInputs("name_input", "");
            toast({
              title: "Solução editada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao editar solução, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      remove: async () => {
        if (Object.keys(projectData["solutions"]).length === 1) {
          toast({
            title:
              "Essa é a única solução do projeto, para removê-la, apague o projeto",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Removendo solução...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await solutionRemove([
              userInputs,
              projectData["name"],
              solutionInputs,
            ]);
            //Optimistic Update
            const updatedSolutionsData = projectData["solutions"];
            delete updatedSolutionsData[solutionInputs["selected_name"]];
            updateProjectData("solutions", updatedSolutionsData);
            setFormSolution("");
            updateSolutionInputs(
              "selected_name",
              Object.keys(projectData["solutions"])[0]
            );
            toast({
              title: "Solução removida com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao remover solução, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  // FOUNDATION SOLUTION
  const [foundationInputs, setFoundationInputs] = useState({
    selected_name: "",
    name: "",
    l: "",
    n: "1",
    rc: 0,
    rt: 0,
    clength: 0,
    tlength: 0,
    length: 1,
    radm: projectData["investigations"][
      investigationInputs["selected_name"]
    ][0]["pa"],
  });

  function updateFoundationInputs(key, value) {
    setFoundationInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function onPileLengthResultsCalculate() {
    if (foundationInputs["l"] === "" || foundationInputs["l"] === "0") {
      toast({
        title: "Preencha todos os campos",
        status: "warning",
        isClosable: true,
      });
    } else {
      const toastId = toast({
        title: "Calculando...",
        status: "loading",
        duration: null,
      });

      pileLengthCalculate([
        projectData["investigations"][investigationInputs["selected_name"]],
        columnInputs,
        foundationInputs,
      ])
        .then((response) => {
          if (response["data"]["clength"] !== null) {
            updateFoundationInputs("rc", response["data"]["rc"]);
            updateFoundationInputs("rt", response["data"]["rt"]);
            updateFoundationInputs("clength", response["data"]["clength"]);
            updateFoundationInputs("tlength", response["data"]["tlength"]);
            toast({
              title: "Calculado com sucesso",
              status: "success",
              isClosable: true,
            });
          } else {
            updateFoundationInputs("rc", 0);
            updateFoundationInputs("rt", 0);
            updateFoundationInputs("clength", 0);
            updateFoundationInputs("tlength", 0);
            toast({
              title:
                "Carregamento na estaca maior que a sua capacidade de carga",
              status: "error",
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          toast({
            title: "Erro ao calcular",
            description: "Por favor, tente novamente mais tarde.",
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          toast.close(toastId);
        });
    }
  }

  function onFoundationAction(action) {
    const options = {
      register: async () => {
        if (
          foundationInputs["name"] === "" ||
          foundationInputs["l"] === "" ||
          foundationInputs["clength"] === 0
        ) {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          projectData["solutions"][solutionInputs["selected_name"]].some(
            (foundation) => foundation["name"] === foundationInputs["name"]
          )
        ) {
          toast({
            title:
              "Uma fundação com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Cadastrando fundação...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await foundationRegister([
              userInputs,
              projectData["name"],
              geotechnicsInputs,
              solutionInputs,
              foundationInputs,
            ]);
            //Optimistic Update
            const updatedSolutionsData = projectData["solutions"];
            const updatedFoundationsData = [
              ...projectData["solutions"][solutionInputs["selected_name"]],
            ];
            const parameters = {
              diameter: geotechnicsInputs["dimension_1"] * 100,
              quantity: foundationInputs["n"],
              rc: foundationInputs["rc"],
              rt: foundationInputs["rt"],
              length: foundationInputs["length"],
              radm: foundationInputs["radm"],
            };
            updatedFoundationsData.push({
              type: geotechnicsInputs["type"],
              name: foundationInputs["name"],
              parameters: parameters,
            });
            updatedSolutionsData[solutionInputs["selected_name"]] =
              updatedFoundationsData;
            updateProjectData("solutions", updatedSolutionsData);
            updateFoundationInputs("selected_name", "");
            updateFoundationInputs("name", "");
            updateFoundationInputs("l", "");
            updateFoundationInputs("n", "1");
            updateFoundationInputs("rc", 0);
            updateFoundationInputs("rt", 0);
            updateFoundationInputs("clength", 0);
            updateFoundationInputs("tlength", 0);
            updateFoundationInputs("length", 1);
            updateFoundationInputs("radm", "");
            toast({
              title: "Fundação cadastrada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao cadastrar fundação, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      edit: async () => {
        if (foundationInputs["selected_name"] === "") {
          toast({
            title: "Para executar essa função, selecione uma fundação",
            status: "warning",
            isClosable: true,
          });
        } else if (
          foundationInputs["name"] === "" ||
          foundationInputs["l"] === "" ||
          foundationInputs["clength"] === 0
        ) {
          toast({
            title: "Preencha todos os campos",
            status: "warning",
            isClosable: true,
          });
        } else if (
          foundationInputs["selected_name"] !== foundationInputs["name"] &&
          projectData["solutions"][solutionInputs["selected_name"]].some(
            (foundation) => foundation["name"] === foundationInputs["name"]
          )
        ) {
          toast({
            title:
              "Uma fundação com esse nome já existe. Escolha um nome diferente",
            status: "error",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Editando fundação...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await foundationEdit([
              userInputs,
              projectData["name"],
              geotechnicsInputs,
              solutionInputs,
              foundationInputs,
            ]);
            //Optimistic Update
            const updatedSolutionsData = projectData["solutions"];
            const updatedFoundationsData = [
              ...projectData["solutions"][solutionInputs["selected_name"]],
            ];
            updatedFoundationsData.map((foundation, i) => {
              if (foundationInputs["selected_name"] === foundation["name"]) {
                const parameters = {
                  diameter: geotechnicsInputs["dimension_1"] * 100,
                  quantity: foundationInputs["n"],
                  rc: foundationInputs["rc"],
                  rt: foundationInputs["rt"],
                  length: foundationInputs["length"],
                  radm: foundationInputs["radm"],
                };
                updatedFoundationsData[i] = {
                  type: geotechnicsInputs["type"],
                  name: foundationInputs["name"],
                  parameters: parameters,
                };
              }
            });
            updatedSolutionsData[solutionInputs["selected_name"]] =
              updatedFoundationsData;
            updateProjectData("solutions", updatedSolutionsData);
            updateFoundationInputs("selected_name", "");
            updateFoundationInputs("name", "");
            updateFoundationInputs("l", "");
            updateFoundationInputs("n", "1");
            updateFoundationInputs("rc", 0);
            updateFoundationInputs("rt", 0);
            updateFoundationInputs("clength", 0);
            updateFoundationInputs("tlength", 0);
            updateFoundationInputs("length", 1);
            updateFoundationInputs("radm", "");
            toast({
              title: "Fundação editada com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao editar fundação, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
      remove: async () => {
        if (foundationInputs["selected_name"] === "") {
          toast({
            title: "Para executar essa função, selecione uma fundação",
            status: "warning",
            isClosable: true,
          });
        } else {
          const toastId = toast({
            title: "Removendo fundação...",
            description: "Aguarde enquanto os dados são processados.",
            status: "loading",
            duration: null,
          });

          try {
            await foundationRemove([
              userInputs,
              projectData["name"],
              solutionInputs,
              foundationInputs,
            ]);
            //Optimistic Update
            const updatedSolutionsData = projectData["solutions"];
            const updatedFoundationsData = [
              ...projectData["solutions"][solutionInputs["selected_name"]],
            ];
            updatedFoundationsData.map((foundation, i) => {
              if (foundationInputs["selected_name"] === foundation["name"])
                updatedFoundationsData.splice(i, 1);
            });
            updatedSolutionsData[solutionInputs["selected_name"]] =
              updatedFoundationsData;
            updateProjectData("solutions", updatedSolutionsData);
            updateFoundationInputs("selected_name", "");
            toast({
              title: "Fundação removida com sucesso",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Erro ao remover fundação, tente novamente mais tarde",
              status: "error",
              isClosable: true,
            });
          } finally {
            toast.close(toastId);
          }
        }
      },
    };
    for (const [key, value] of Object.entries(options)) {
      if (action === key) {
        value();
      }
    }
  }

  // EXPORT FOUNDATIONS
  const [reportInputs, setReportInputs] = useState("Resumo de Fundações");

  function onExport() {
    if (
      projectData["solutions"][solutionInputs["selected_name"]].length === 0
    ) {
      toast({
        title: "Cadastre ao menos uma fundação para exportar um resumo",
        status: "warning",
        isClosable: true,
      });
    } else {
      const toastId = toast({
        title: "Exportando resumo...",
        status: "loading",
        duration: null,
      });
      if (reportInputs === "Resumo de Fundações") {
        solutionMemorialExcelBuilder(projectData, solutionInputs)
          .then((response) => {
            toast({
              title: "Resumo gerado com sucesso",
              status: "success",
              isClosable: true,
            });
          })
          .catch((error) => {
            toast({
              title: "Erro ao gerar resumo",
              description: "Por favor, tente novamente mais tarde.",
              status: "error",
              isClosable: true,
            });
          })
          .finally(() => {
            toast.close(toastId);
          });
      }
    }
  }

  return (
    <>
      <div className={styles.mainStructure}>
        <div className={styles.sectionGeotechnics}>
          <div className={styles.sectionGeotechnicsHeader}>
            <Text>
              Método: <strong>{geotechnicsInputs["method"]}</strong>
            </Text>
            <Text>
              Diâmetro ={" "}
              <strong>{geotechnicsInputs["dimension_1"] * 100} cm</strong>
            </Text>
          </div>
          <div className={styles.sectionGeotechnicsSection}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Cota</Th>
                  <Th>(-) C. Adm (kn)</Th>
                  {/* <Th>(+) C. Adm (kn)</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {projectData["investigations"][
                  investigationInputs["selected_name"]
                ].map((camada, i) =>
                  camada["pa"] !== "" && camada["pa"] !== "-" ? (
                    <Tr
                      aria-selected={
                        foundationInputs["clength"] === i + 1 ? "true" : "false"
                      }
                      key={"row-" + i}
                    >
                      <Td>
                        {camada["elevation"] - geotechnicsInputs["dimension_3"]}
                      </Td>
                      <Td>{camada["pa"]}</Td>
                    </Tr>
                  ) : null
                )}
              </Tbody>
            </Table>
          </div>
          <div className={styles.sectionGeotechnicsFooter}>
            <Text>
              Sondagem: <strong>{investigationInputs["selected_name"]}</strong>
            </Text>
          </div>
        </div>
        <div className={styles.sectionStructure}>
          <div className={styles.structureHeader}>
            <div className={styles.structureSectionRow}>
              <div>ESFORÇOS:</div>
            </div>
            <div className={styles.structureSectionRow}>
              <Tooltip
                placement="right"
                isDisabled={
                  userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                  userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                  userInputs["licenses"]["fundars"]["plan"] === "tester"
                }
                label={
                  <span>
                    Para usar essa função habilite a licença{" "}
                    <LicenseTag plan="pro" /> ou uma superior
                  </span>
                }
              >
                <Button
                  name="manage"
                  size="sm"
                  isDisabled={
                    userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                    userInputs["licenses"]["fundars"]["plan"] !== "premium" &&
                    userInputs["licenses"]["fundars"]["plan"] !== "tester"
                  }
                  onClick={() => {
                    updateColumnInputs("selected_name", "");
                    updateColumnInputs("name", "");
                    updateColumnInputs("fz", "");
                    updateColumnInputs("mx", "");
                    updateColumnInputs("my", "");
                    setFormColumn(true);
                  }}
                >
                  Gerenciar
                </Button>
              </Tooltip>
              <Select
                name="column"
                onChange={(ev) => {
                  updateColumnInputs("selected_name", ev.target.value);
                  projectData["columns"].map((column, i) => {
                    if (column["name"] === ev.target.value) {
                      updateColumnInputs("fz", column["fz"]);
                      updateColumnInputs("mx", column["mx"]);
                      updateColumnInputs("my", column["my"]);
                    }
                  });
                }}
                variant="outline"
                w="150px"
                size="xs"
                fontSize="md"
                value={columnInputs["selected_name"]}
              >
                {projectData["columns"].map((column, i) => (
                  <option key={i} value={column["name"]}>
                    {" "}
                    {column["name"]}{" "}
                  </option>
                ))}
              </Select>
              <Text fontSize="md">Fz= {columnInputs["fz"]} kN</Text>
              <Text fontSize="md">Mx= {columnInputs["mx"]} kNm</Text>
              <Text fontSize="md">My= {columnInputs["my"]} kNm</Text>
            </div>
            <div className={styles.structureSectionRow}>
              <div>FUNDAÇÃO:</div>
            </div>
            <div className={styles.structureSectionRow}>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">L(m)=</Text>
                <Input
                  name="l"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(ev) =>
                    updateFoundationInputs("l", ev.target.value)
                  }
                  value={foundationInputs["l"]}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
              </div>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">Nº de estacas=</Text>
                <Select
                  name="n"
                  onChange={(ev) =>
                    updateFoundationInputs("n", ev.target.value)
                  }
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={foundationInputs["n"]}
                >
                  {pileCapTypes["pileQuantities"].map((name, i) => (
                    <option key={i} value={name}>
                      {" "}
                      {name}{" "}
                    </option>
                  ))}
                </Select>
              </div>
              <Tooltip
                placement="left"
                isDisabled={
                  userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                  userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                  userInputs["licenses"]["fundars"]["plan"] === "tester"
                }
                label={
                  <span>
                    Para usar essa função habilite a licença{" "}
                    <LicenseTag plan="pro" /> ou uma superior
                  </span>
                }
              >
                <Button
                  name="calculate_1"
                  size="sm"
                  isDisabled={
                    userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                    userInputs["licenses"]["fundars"]["plan"] !== "premium" &&
                    userInputs["licenses"]["fundars"]["plan"] !== "tester"
                  }
                  onClick={onPileLengthResultsCalculate}
                >
                  Calcular Comprimento
                </Button>
              </Tooltip>
            </div>
            <div className={styles.structureSectionRow}>
              <Text fontSize="md">
                Rc= {foundationInputs["rc"].toFixed(2)} kN
              </Text>
              <Text fontSize="md">
                Rt= {foundationInputs["rt"].toFixed(2)} kN
              </Text>
              <Text fontSize="md">
                Compressão= {foundationInputs["clength"]} m
              </Text>
              <Text fontSize="md">Tração= {foundationInputs["tlength"]} m</Text>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">Adot.(m)=</Text>
                <Select
                  name="length"
                  onChange={(ev) => {
                    updateFoundationInputs("length", ev.target.value);
                    updateFoundationInputs(
                      "radm",
                      projectData["investigations"][
                        investigationInputs["selected_name"]
                      ][ev.target.value - 1]["pa"]
                    );
                  }}
                  variant="outline"
                  w="60px"
                  size="xs"
                  fontSize="md"
                  value={foundationInputs["length"]}
                >
                  {[
                    ...Array(
                      projectData["investigations"][
                        investigationInputs["selected_name"]
                      ].length
                    ).keys(),
                  ].map((element) => (
                    <option key={element + 1}>{element + 1}</option>
                  ))}
                </Select>
              </div>
            </div>
            <div className={styles.structureSectionRow}>
              <div className={styles.structureSectionElement}>
                <Text fontSize="md">Fundação:</Text>
                <Input
                  name="name"
                  onChange={(ev) =>
                    updateFoundationInputs("name", ev.target.value)
                  }
                  value={foundationInputs["name"]}
                  width="50px"
                  size="xs"
                  fontSize="md"
                />
                <Tooltip
                  placement="bottom"
                  isDisabled={
                    userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                    userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                    userInputs["licenses"]["fundars"]["plan"] === "tester"
                  }
                  label={
                    <span>
                      Para usar essa função habilite a licença{" "}
                      <LicenseTag plan="pro" /> ou uma superior
                    </span>
                  }
                >
                  <IconButton
                    variant="icon"
                    icon={
                      <Tooltip
                        hasArrow
                        label="Criar"
                        bg="gray"
                        color="black"
                        fontSize="md"
                        isDisabled={
                          userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                          userInputs["licenses"]["fundars"]["plan"] !==
                            "premium" &&
                          userInputs["licenses"]["fundars"]["plan"] !== "tester"
                        }
                      >
                        <AddIcon />
                      </Tooltip>
                    }
                    isDisabled={
                      userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                      userInputs["licenses"]["fundars"]["plan"] !== "premium" &&
                      userInputs["licenses"]["fundars"]["plan"] !== "tester"
                    }
                    onClick={() => onFoundationAction("register")}
                  />
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  isDisabled={
                    userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                    userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                    userInputs["licenses"]["fundars"]["plan"] === "tester"
                  }
                  label={
                    <span>
                      Para usar essa função habilite a licença{" "}
                      <LicenseTag plan="pro" /> ou uma superior
                    </span>
                  }
                >
                  <IconButton
                    variant="icon"
                    icon={
                      <Tooltip
                        hasArrow
                        label="Editar"
                        bg="gray"
                        color="black"
                        fontSize="md"
                        isDisabled={
                          userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                          userInputs["licenses"]["fundars"]["plan"] !==
                            "premium" &&
                          userInputs["licenses"]["fundars"]["plan"] !== "tester"
                        }
                      >
                        <EditIcon />
                      </Tooltip>
                    }
                    isDisabled={
                      userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                      userInputs["licenses"]["fundars"]["plan"] !== "premium" &&
                      userInputs["licenses"]["fundars"]["plan"] !== "tester"
                    }
                    onClick={() => onFoundationAction("edit")}
                  />
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  isDisabled={
                    userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                    userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                    userInputs["licenses"]["fundars"]["plan"] === "tester"
                  }
                  label={
                    <span>
                      Para usar essa função habilite a licença{" "}
                      <LicenseTag plan="pro" /> ou uma superior
                    </span>
                  }
                >
                  <IconButton
                    variant="icon"
                    icon={
                      <Tooltip
                        hasArrow
                        label="Remover"
                        bg="gray"
                        color="black"
                        fontSize="md"
                        isDisabled={
                          userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                          userInputs["licenses"]["fundars"]["plan"] !==
                            "premium" &&
                          userInputs["licenses"]["fundars"]["plan"] !== "tester"
                        }
                      >
                        <DeleteIcon />
                      </Tooltip>
                    }
                    isDisabled={
                      userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                      userInputs["licenses"]["fundars"]["plan"] !== "premium" &&
                      userInputs["licenses"]["fundars"]["plan"] !== "tester"
                    }
                    onClick={() => onFoundationAction("remove")}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className={styles.structureSection}>
            <Table variant="simple">
              <Thead>
                <Tr key="solution-header">
                  {solutionHeaders["foundations"]["headers"].map(
                    (header, i) => (
                      <Th key={"th" + i}>{header}</Th>
                    )
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {projectData["solutions"][solutionInputs["selected_name"]] &&
                projectData["solutions"][solutionInputs["selected_name"]]
                  .length !== 0 ? (
                  projectData["solutions"][solutionInputs["selected_name"]].map(
                    (foundation, i) => (
                      <Tr
                        aria-selected={
                          foundationInputs["selected_name"] ===
                          foundation["name"]
                            ? "true"
                            : "false"
                        }
                        key={"row-" + i}
                      >
                        {solutionHeaders["foundations"]["values"].map(
                          (h, _i) => {
                            return h === "type" || h === "name" ? (
                              <Td
                                key={"col-" + _i}
                                onClick={() =>
                                  updateFoundationInputs(
                                    "selected_name",
                                    foundation["name"]
                                  )
                                }
                              >
                                {foundation[h]}
                              </Td>
                            ) : h === "rc" || h === "rt" || h === "radm" ? (
                              <Td
                                key={"col-" + _i}
                                onClick={() =>
                                  updateFoundationInputs(
                                    "selected_name",
                                    foundation["name"]
                                  )
                                }
                              >
                                {foundation["parameters"][h].toFixed(2)}
                              </Td>
                            ) : (
                              <Td
                                key={"col-" + _i}
                                onClick={() =>
                                  updateFoundationInputs(
                                    "selected_name",
                                    foundation["name"]
                                  )
                                }
                              >
                                {foundation["parameters"][h]}
                              </Td>
                            );
                          }
                        )}
                      </Tr>
                    )
                  )
                ) : (
                  <Tr key={"row-0"}>
                    <Td>-</Td>
                    <Td>-</Td>
                    <Td>-</Td>
                    <Td>-</Td>
                    <Td>-</Td>
                    <Td>-</Td>
                    <Td>-</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </div>
          <div className={styles.structureFooter}>
            <div className={styles.structureSectionElement}>
              <Select
                name="selected_name"
                onChange={(ev) =>
                  updateSolutionInputs("selected_name", ev.target.value)
                }
                variant="outline"
                w="150px"
                size="sm"
                fontSize="md"
                value={solutionInputs["selected_name"]}
              >
                {Object.keys(projectData["solutions"])
                  .sort((a, b) => a.localeCompare(b))
                  .map((solutionName, i) => (
                    <option key={i} value={solutionName}>
                      {" "}
                      {solutionName}{" "}
                    </option>
                  ))}
              </Select>
              <Popover
                placement="bottom"
                isOpen={formSolution === "register"}
                onOpen={() => setFormSolution("register")}
                onClose={() => setFormSolution("")}
              >
                <PopoverTrigger>
                  {userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                  userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                  userInputs["licenses"]["fundars"]["plan"] === "tester" ? (
                    <IconButton
                      variant="icon"
                      icon={
                        <Tooltip
                          hasArrow
                          label="Criar"
                          bg="gray"
                          color="black"
                          fontSize="md"
                          isDisabled={
                            userInputs["licenses"]["fundars"]["plan"] !==
                              "pro" &&
                            userInputs["licenses"]["fundars"]["plan"] !==
                              "premium" &&
                            userInputs["licenses"]["fundars"]["plan"] !==
                              "tester"
                          }
                        >
                          <AddIcon />
                        </Tooltip>
                      }
                      isDisabled={
                        userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                        userInputs["licenses"]["fundars"]["plan"] !==
                          "premium" &&
                        userInputs["licenses"]["fundars"]["plan"] !== "tester"
                      }
                    />
                  ) : (
                    <Tooltip
                      placement="top"
                      isDisabled={
                        userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                        userInputs["licenses"]["fundars"]["plan"] ===
                          "premium" ||
                        userInputs["licenses"]["fundars"]["plan"] === "tester"
                      }
                      label={
                        <span>
                          Para usar essa função habilite a licença{" "}
                          <LicenseTag plan="pro" /> ou uma superior
                        </span>
                      }
                    >
                      <IconButton
                        variant="icon"
                        icon={<AddIcon />}
                        isDisabled={
                          userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                          userInputs["licenses"]["fundars"]["plan"] !==
                            "premium" &&
                          userInputs["licenses"]["fundars"]["plan"] !== "tester"
                        }
                      />
                    </Tooltip>
                  )}
                </PopoverTrigger>
                <PopoverContent flexDirection={"row"}>
                  <PopoverArrow backgroundColor={"black"} />
                  <Input
                    name="name_input"
                    type="text"
                    placeholder="Digite o nome da solução"
                    onChange={(ev) =>
                      updateSolutionInputs("name_input", ev.target.value)
                    }
                    value={solutionInputs["name_input"]}
                  />
                  <IconButton
                    variant="icon"
                    icon={<CheckIcon />}
                    onClick={() => onSolutionAction(formSolution)}
                  />
                  <IconButton
                    variant="icon"
                    icon={<CloseIcon />}
                    onClick={() => {
                      setFormSolution("");
                      updateSolutionInputs("name_input", "");
                    }}
                  />
                </PopoverContent>
              </Popover>
              <Popover
                placement="bottom"
                isOpen={formSolution === "edit"}
                onOpen={() => setFormSolution("edit")}
                onClose={() => setFormSolution("")}
              >
                <PopoverTrigger>
                  {userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                  userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                  userInputs["licenses"]["fundars"]["plan"] === "tester" ? (
                    <IconButton
                      variant="icon"
                      icon={
                        <Tooltip
                          hasArrow
                          label="Editar"
                          bg="gray"
                          color="black"
                          fontSize="md"
                          isDisabled={
                            userInputs["licenses"]["fundars"]["plan"] !==
                              "pro" &&
                            userInputs["licenses"]["fundars"]["plan"] !==
                              "premium" &&
                            userInputs["licenses"]["fundars"]["plan"] !==
                              "tester"
                          }
                        >
                          <EditIcon />
                        </Tooltip>
                      }
                      isDisabled={
                        userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                        userInputs["licenses"]["fundars"]["plan"] !==
                          "premium" &&
                        userInputs["licenses"]["fundars"]["plan"] !== "tester"
                      }
                    />
                  ) : (
                    <Tooltip
                      placement="top"
                      isDisabled={
                        userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                        userInputs["licenses"]["fundars"]["plan"] ===
                          "premium" ||
                        userInputs["licenses"]["fundars"]["plan"] === "tester"
                      }
                      label={
                        <span>
                          Para usar essa função habilite a licença{" "}
                          <LicenseTag plan="pro" /> ou uma superior
                        </span>
                      }
                    >
                      <IconButton
                        variant="icon"
                        icon={<EditIcon />}
                        isDisabled={
                          userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                          userInputs["licenses"]["fundars"]["plan"] !==
                            "premium" &&
                          userInputs["licenses"]["fundars"]["plan"] !== "tester"
                        }
                      />
                    </Tooltip>
                  )}
                </PopoverTrigger>
                <PopoverContent flexDirection={"row"}>
                  <PopoverArrow backgroundColor={"black"} />
                  <Input
                    name="name_input"
                    type="text"
                    placeholder="Digite o nome da solução"
                    onChange={(ev) =>
                      updateSolutionInputs("name_input", ev.target.value)
                    }
                    value={solutionInputs["name_input"]}
                  />
                  <IconButton
                    variant="icon"
                    icon={<CheckIcon />}
                    onClick={() => onSolutionAction(formSolution)}
                  />
                  <IconButton
                    variant="icon"
                    icon={<CloseIcon />}
                    onClick={() => {
                      setFormSolution("");
                      updateSolutionInputs("name_input", "");
                    }}
                  />
                </PopoverContent>
              </Popover>
              <Tooltip
                placement="top"
                isDisabled={
                  userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                  userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                  userInputs["licenses"]["fundars"]["plan"] === "tester"
                }
                label={
                  <span>
                    Para usar essa função habilite a licença{" "}
                    <LicenseTag plan="pro" /> ou uma superior
                  </span>
                }
              >
                <IconButton
                  variant="icon"
                  icon={
                    <Tooltip
                      hasArrow
                      label="Remover"
                      bg="gray"
                      color="black"
                      fontSize="md"
                      isDisabled={
                        userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                        userInputs["licenses"]["fundars"]["plan"] !==
                          "premium" &&
                        userInputs["licenses"]["fundars"]["plan"] !== "tester"
                      }
                    >
                      <DeleteIcon />
                    </Tooltip>
                  }
                  isDisabled={
                    userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                    userInputs["licenses"]["fundars"]["plan"] !== "premium" &&
                    userInputs["licenses"]["fundars"]["plan"] !== "tester"
                  }
                  onClick={() => setFormSolution("remove")}
                />
              </Tooltip>
              <Tooltip
                placement="top"
                isDisabled={
                  userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                  userInputs["licenses"]["fundars"]["plan"] === "tester"
                }
                label={
                  <span>
                    Para usar essa função habilite a licença{" "}
                    <LicenseTag plan="premium" />
                  </span>
                }
              >
                <Button
                  size={"sm"}
                  isDisabled={
                    userInputs["licenses"]["fundars"]["plan"] !== "premium" &&
                    userInputs["licenses"]["fundars"]["plan"] !== "tester"
                  }
                  onClick={() => setFormSolution("compare")}
                >
                  Comparar
                </Button>
              </Tooltip>
            </div>
            <div className={styles.structureSectionElement}>
              <Tooltip
                placement="left"
                isDisabled={
                  userInputs["licenses"]["fundars"]["plan"] === "pro" ||
                  userInputs["licenses"]["fundars"]["plan"] === "premium" ||
                  userInputs["licenses"]["fundars"]["plan"] === "tester"
                }
                label={
                  <span>
                    Para usar essa função habilite a licença{" "}
                    <LicenseTag plan="pro" /> ou uma superior
                  </span>
                }
              >
                <Button
                  size={"sm"}
                  isDisabled={
                    userInputs["licenses"]["fundars"]["plan"] !== "pro" &&
                    userInputs["licenses"]["fundars"]["plan"] !== "premium" &&
                    userInputs["licenses"]["fundars"]["plan"] !== "tester"
                  }
                  onClick={() => setFormSolution("export")}
                >
                  Exportar
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {formColumn && (
        <Modal
          isOpen={formColumn}
          onClose={() => {
            setFormColumn(false);
            updateColumnInputs(
              "selected_name",
              projectData["columns"][0]["name"]
            );
            updateColumnInputs("name", projectData["columns"][0]["name"]);
            updateColumnInputs("fz", projectData["columns"][0]["fz"]);
            updateColumnInputs("mx", projectData["columns"][0]["mx"]);
            updateColumnInputs("my", projectData["columns"][0]["my"]);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="md">LISTA DE PILARES</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className={styles.structureSectionRow}>
                <div className={styles.structureSectionElement}>
                  <Text fontSize="md">Nome=</Text>
                  <Input
                    name="name"
                    onChange={(ev) =>
                      updateColumnInputs("name", ev.target.value)
                    }
                    width="50px"
                    size="xs"
                    fontSize="md"
                    value={columnInputs["name"]}
                  />
                </div>
                <div className={styles.structureSectionElement}>
                  <Text fontSize="md">Fz=</Text>
                  <Input
                    name="fz"
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(ev) => updateColumnInputs("fz", ev.target.value)}
                    width="50px"
                    size="xs"
                    fontSize="md"
                    value={columnInputs["fz"]}
                  />
                </div>
                <div className={styles.structureSectionElement}>
                  <Text fontSize="md">Mx=</Text>
                  <Input
                    name="mx"
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(ev) => updateColumnInputs("mx", ev.target.value)}
                    width="50px"
                    size="xs"
                    fontSize="md"
                    value={columnInputs["mx"]}
                  />
                </div>
                <div className={styles.structureSectionElement}>
                  <Text fontSize="md">My=</Text>
                  <Input
                    name="my"
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(ev) => updateColumnInputs("my", ev.target.value)}
                    width="50px"
                    size="xs"
                    fontSize="md"
                    value={columnInputs["my"]}
                  />
                </div>
              </div>
              <div className={styles.structureSectionRow}>
                <Button
                  name="column_register"
                  width="100px"
                  onClick={() => {
                    onColumnAction("register");
                  }}
                  colorScheme="blue"
                  size="sm"
                  fontSize="md"
                >
                  Cadastrar
                </Button>
                <Button
                  name="column_edit"
                  width="100px"
                  onClick={() => {
                    onColumnAction("edit");
                  }}
                  colorScheme="blue"
                  size="sm"
                  fontSize="md"
                >
                  Editar
                </Button>
                <Button
                  name="column_remove"
                  width="100px"
                  onClick={() => {
                    onColumnAction("remove");
                  }}
                  colorScheme="blue"
                  size="sm"
                  fontSize="md"
                >
                  Remover
                </Button>
              </div>
              <div className={styles.structureModalSection}>
                <Table variant="simple">
                  <Thead>
                    <Tr key={"header"}>
                      {solutionHeaders["columns"]["headers"].map(
                        (header, i) => (
                          <Th fontSize="md" key={"head-" + i}>
                            {header}
                          </Th>
                        )
                      )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {projectData["columns"].map((column, j) => (
                      <Tr
                        aria-selected={
                          columnInputs["selected_name"] === column["name"]
                            ? "true"
                            : "false"
                        }
                        key={"row-" + j}
                      >
                        <Td
                          key={"col-name-" + j}
                          onClick={() => {
                            updateColumnInputs("selected_name", column["name"]);
                          }}
                        >
                          {column["name"]}
                        </Td>
                        <Td
                          key={"col-fz-" + j}
                          onClick={() => {
                            updateColumnInputs("selected_name", column["name"]);
                          }}
                        >
                          {column["fz"]}
                        </Td>
                        <Td
                          key={"col-mx-" + j}
                          onClick={() => {
                            updateColumnInputs("selected_name", column["name"]);
                          }}
                        >
                          {column["mx"]}
                        </Td>
                        <Td
                          key={"col-my-" + j}
                          onClick={() => {
                            updateColumnInputs("selected_name", column["name"]);
                          }}
                        >
                          {column["my"]}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {formSolution === "remove" && solutionInputs["selected_name"] !== "" && (
        <Modal isCentered isOpen={formSolution !== ""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Excluir {solutionInputs["selected_name"]}</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormSolution("");
              }}
            />
            <ModalBody>
              Deseja prosseguir? Essa ação não poderá ser desfeita
            </ModalBody>
            <ModalFooter>
              <Button
                variant="delete"
                onClick={() => onSolutionAction(formSolution)}
              >
                EXCLUIR
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {formSolution === "export" && (
        <Modal isCentered isOpen={formSolution !== ""} onClose={""}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Gerar Relatório</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormSolution("");
                setReportInputs("Memorial de Cálculo - Camada Única");
              }}
            />
            <ModalBody>
              <FormControl>
                <FormLabel>Tipo de Relatório à exportar:</FormLabel>
                <Select
                  value={reportInputs}
                  onChange={(ev) => setReportInputs(ev.target.value)}
                >
                  <optgroup label={"Excel"}>
                    <option key={"Resumo de Fundações"}>
                      {"Resumo de Fundações"}
                    </option>
                  </optgroup>
                </Select>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onExport}>BAIXAR</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {formSolution === "compare" && (
        <Modal isCentered isOpen={formSolution !== ""} onClose={""}>
          <ModalOverlay />
          <ModalContent w={"800px"}>
            <ModalHeader>Comparador de Soluções</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setFormSolution("");
              }}
            />
            <ModalBody>
              <GraphsCompare projectData={projectData} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Structure;
