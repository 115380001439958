import { useState } from "react";
import styles from "./Login.module.css";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../../../firebase.js";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";

import { api } from "../../../utils/services/api";

function Login({ updateUserInputs, setSelectedTab }) {
  const toast = useToast();
  const provider = new GoogleAuthProvider();
  const [userPage, setUserPage] = useState("Login");
  const [loginInputs, setLoginInputs] = useState({
    email: "",
    password: "",
  });
  const [registerInputs, setRegisterInputs] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [forgotPasswordInputs, setForgotPasswordInputs] = useState({
    email: "",
  });

  function updateLoginInputs(key, value) {
    setLoginInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function updateRegisterInputs(key, value) {
    setRegisterInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function updateForgotPasswordInputs(key, value) {
    setForgotPasswordInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  }

  function register() {
    if (
      registerInputs["name"] === "" ||
      registerInputs["email"] === "" ||
      registerInputs["password"] === "" ||
      registerInputs["password_confirmation"] === ""
    ) {
      toast({
        title: "Preencha todos os campos",
        status: "warning",
        isClosable: true,
      });
    } else if (
      registerInputs["password"] !== registerInputs["password_confirmation"]
    ) {
      toast({
        title: "As senhas não coincidem. Por favor, digite novamente.",
        status: "warning",
        isClosable: true,
      });
    } else {
      const toastId = toast({
        title: "Cadastrando...",
        description: "Aguarde um momento.",
        status: "loading",
        duration: null,
      });
      createUserWithEmailAndPassword(
        auth,
        registerInputs["email"],
        registerInputs["password"]
      )
        .then((userCredential) => {
          const user = userCredential.user;
          updateProfile(user, {
            displayName: registerInputs["name"],
          });
          api
            .post("/subscription/register", {
              id: user["uid"],
              name: user["displayName"],
              email: user["email"],
            })
            .then((response) => {
              updateUserInputs("licenses", response["data"]);
            });
          toast({
            title: "Cadastro realizado com sucesso",
            status: "success",
            isClosable: true,
          });
          setUserPage("Login");
          setRegisterInputs({
            name: "",
            email: "",
            password: "",
            password_confirmation: "",
          });
        })
        .catch((error) => {
          let errorMessage = "Erro ao cadastrar, tente novamente mais tarde";
          if (error.code === "auth/email-already-in-use") {
            errorMessage = "Este e-mail já está em uso.";
          } else if (error.code === "auth/weak-password") {
            errorMessage = "A senha deve ter pelo menos 6 caracteres.";
          } else if (error.code === "auth/invalid-email") {
            errorMessage = "E-mail inválido. Verifique o formato.";
          }
          toast({
            title: errorMessage,
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          toast.close(toastId);
        });
    }
  }

  function login() {
    if (loginInputs["email"] === "" || loginInputs["password"] === "") {
      toast({
        title: "Preencha todos os campos",
        status: "warning",
        isClosable: true,
      });
    } else {
      window.localStorage.removeItem("user_id");
      window.localStorage.removeItem("user_name");
      window.localStorage.removeItem("user_email");
      const toastId = toast({
        title: "Logando...",
        description: "Aguarde um momento.",
        status: "loading",
        duration: null,
      });
      signInWithEmailAndPassword(
        auth,
        loginInputs["email"],
        loginInputs["password"]
      )
        .then((userCredential) => {
          const user = userCredential.user;
          updateUserInputs("id", user["uid"]);
          updateUserInputs("name", user["displayName"]);
          updateUserInputs("email", user["email"]);
          api.post("/subscription", { id: user["uid"] }).then((response) => {
            updateUserInputs("licenses", response["data"]);
          });
          setSelectedTab("Softwares");
          toast({
            title: "Login realizado com sucesso",
            status: "success",
            isClosable: true,
          });
          setLoginInputs({
            email: "",
            password: "",
          });
        })
        .catch((error) => {
          console.log(error.code, error.message);
          let errorMessage = "Erro ao logar, tente novamente mais tarde";
          if (error.code === "auth/invalid-login-credentials") {
            errorMessage = "E-mail ou senha incorretos.";
          } else if (error.code === "auth/invalid-email") {
            errorMessage = "E-mail inválido. Verifique o formato.";
          } else if (error.code === "auth/too-many-requests") {
            errorMessage =
              "Muitas tentativas falhas. Tente novamente mais tarde.";
          }
          toast({
            title: errorMessage,
            status: "error",
            isClosable: true,
          });
        })
        .finally(() => {
          toast.close(toastId);
        });
    }
  }

  function loginGoogle() {
    window.localStorage.removeItem("user_id");
    window.localStorage.removeItem("user_name");
    window.localStorage.removeItem("user_email");
    const toastId = toast({
      title: "Logando...",
      description: "Aguarde um momento.",
      status: "loading",
      duration: null,
    });
    signInWithPopup(auth, provider)
      .then((userCredential) => {
        const user = userCredential.user;
        updateUserInputs("id", user["uid"]);
        updateUserInputs("name", user["displayName"]);
        updateUserInputs("email", user["email"]);
        api.post("/subscription", { id: user["uid"] }).then((response) => {
          updateUserInputs("licenses", response["data"]);
        });
        setSelectedTab("Softwares");
        toast({
          title: "Login realizado com sucesso",
          status: "success",
          isClosable: true,
        });
        setLoginInputs({
          email: "",
          password: "",
        });
      })
      .catch((error) => {
        let errorMessage = "Erro ao logar, tente novamente mais tarde";
        toast({
          title: errorMessage,
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        toast.close(toastId);
      });
  }

  function onForgotPassword() {
    if (forgotPasswordInputs["email"] === "") {
      toast({
        title: "Preencha todos os campos",
        status: "warning",
        isClosable: true,
      });
      return;
    }
    const toastId = toast({
      title: "Enviando email...",
      description: "Aguarde um momento.",
      status: "loading",
      duration: null,
    });
    sendPasswordResetEmail(auth, forgotPasswordInputs["email"])
      .then(() => {
        setUserPage("Login");
        toast({
          title: "E-mail de recuperação de senha enviado!",
          description:
            "Verifique sua caixa de entrada para redefinir sua senha, caso não encontre, verifique a caixa de spam",
          status: "success",
          isClosable: true,
        });
        updateForgotPasswordInputs("email", "");
      })
      .catch((error) => {
        let errorMessage = "Erro ao enviar e-mail de recuperação.";
        if (error.code === "auth/user-not-found") {
          errorMessage = "E-mail não cadastrado.";
        } else if (error.code === "auth/invalid-email") {
          errorMessage = "E-mail inválido.";
        }
        toast({
          title: errorMessage,
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        toast.close(toastId);
      });
  }

  return (
    <>
      {userPage === "Login" && (
        <div className={styles.section}>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Email:</FormLabel>
            <Input
              type="email"
              value={loginInputs["email"]}
              onChange={(ev) => updateLoginInputs("email", ev.target.value)}
            />
          </FormControl>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Senha:</FormLabel>
            <Input
              type="password"
              value={loginInputs["password"]}
              onChange={(ev) => updateLoginInputs("password", ev.target.value)}
            />
            <div
              className={styles.forgotPassword}
              onClick={() => {
                setLoginInputs({
                  email: "",
                  password: "",
                });
                setUserPage("Forgot Password");
              }}
            >
              Esqueceu a senha?
            </div>
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            justifyContent="flex-end"
          >
            <Button w="100%" onClick={login}>
              Entrar
            </Button>
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <FormLabel display="flex" textAlign="center">
              Ou prossiga com:
            </FormLabel>
            <GoogleOAuthProvider clientId="553841292986-ed2t8vf5b9oanqks934tejeh28olmhkk.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={loginGoogle}
                onError={(error) => console.log("O login falhou:", error)}
              />
            </GoogleOAuthProvider>
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <FormLabel display="flex" textAlign="center">
              Ainda não possui uma conta?
            </FormLabel>
            <Button
              variant="outline"
              onClick={() => {
                setUserPage("Register");
                setLoginInputs({
                  email: "",
                  password: "",
                });
              }}
            >
              Registrar-se
            </Button>
          </FormControl>
        </div>
      )}
      {userPage === "Register" && (
        <div className={styles.section}>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Nome:</FormLabel>
            <Input
              value={registerInputs["name"]}
              onChange={(ev) => updateRegisterInputs("name", ev.target.value)}
            />
          </FormControl>
          {/* <FormControl w="300px" padding="5px 0px">
          <FormLabel>Logo:</FormLabel>
          <Input
            type="image"
            value={registerInputs["image"]}
            onChange={(ev) => updateRegisterInputs("image", ev.target.value)}
          />
        </FormControl> */}
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Email:</FormLabel>
            <Input
              type="email"
              value={registerInputs["email"]}
              onChange={(ev) => updateRegisterInputs("email", ev.target.value)}
            />
          </FormControl>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Senha:</FormLabel>
            <Input
              type="password"
              value={registerInputs["password"]}
              onChange={(ev) =>
                updateRegisterInputs("password", ev.target.value)
              }
            />
          </FormControl>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Confirmação de senha:</FormLabel>
            <Input
              type="password"
              value={registerInputs["password_confirmation"]}
              onChange={(ev) =>
                updateRegisterInputs("password_confirmation", ev.target.value)
              }
            />
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            justifyContent="flex-end"
          >
            <Button w="100%" onClick={register}>
              Registrar
            </Button>
          </FormControl>
          <FormControl
            w="300px"
            padding="5px 0px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <FormLabel display="flex" textAlign="center">
              Já possui uma conta?
            </FormLabel>
            <Button
              variant="outline"
              onClick={() => {
                setUserPage("Login");
                setRegisterInputs({
                  name: "",
                  email: "",
                  password: "",
                });
              }}
            >
              Logar
            </Button>
          </FormControl>
        </div>
      )}
      {userPage === "Forgot Password" && (
        <div className={styles.section}>
          <FormControl w="300px" padding="5px 0px">
            <FormLabel>Email:</FormLabel>
            <Input
              type="email"
              value={forgotPasswordInputs["email"]}
              onChange={(ev) =>
                updateForgotPasswordInputs("email", ev.target.value)
              }
            />
          </FormControl>
          <FormControl w="300px" padding="5px 0px">
            <Button w="100%" onClick={onForgotPassword} marginBottom={"5px"}>
              Solicitar Troca de Senha
            </Button>
            <FormLabel
              textAlign={"center"}
              cursor={"pointer"}
              onClick={() => {
                updateForgotPasswordInputs("email", "");
                setUserPage("Login");
              }}
            >
              Voltar
            </FormLabel>
          </FormControl>
        </div>
      )}
    </>
  );
}

export default Login;
