import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

async function memorialExcelBuilder(geotechnicsData, geotechnicsInputs) {
  const headers = [
    [
      "Cota",
      "Nspt",
      "Solo",
      "Resistência Lateral",
      "Resistência Lateral Acumulada",
      "Resistência Ponta",
      "C. Rup. (kN)",
      "C. Adm. (kN)",
    ],
  ];

  const rows = geotechnicsData
    .filter((layer) => layer["pa"] !== "")
    .map((layer, i) => [
      layer["elevation"],
      layer["nspt"],
      layer["soil"],
      layer["rl"],
      layer["rl_accumulated"],
      layer["rp"],
      layer["ru"],
      layer["pa"],
    ]);

  const sheetData = [...headers, ...rows];

  // Criar um novo workbook e uma planilha
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(sheetData);

  // Definir larguras variáveis para cada coluna
  ws["!cols"] = [
    { wch: 8 }, // Cota
    { wch: 8 }, // Nspt
    { wch: 20 }, // Solo
    { wch: 16 }, // Resistência Lateral
    { wch: 26 }, // Resistência Lateral Acumulada
    { wch: 15 }, // Resistência Ponta
    { wch: 10 }, // C. Rup. (kN)
    { wch: 10 }, // C. Adm. (kN)
  ];

  // Adicionar a planilha ao workbook
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    "Ø" +
      geotechnicsInputs["dimension_1"] * 100 +
      " - " +
      geotechnicsInputs["method"]
  );

  // Gerar um Blob para download
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(blob, "Resumo de Capacidades.xlsx");
}

export default memorialExcelBuilder;
