import { useEffect, useState } from "react";
import styles from "./User.module.css";

import { Button, useToast } from "@chakra-ui/react";

import { api } from "../../../utils/services/api";

import LicenseTag from "../../../components/LicenseTag";
import { useStripe } from "@stripe/react-stripe-js";

import { FaCreditCard } from "react-icons/fa";

function User({ userInputs, popupSelected }) {
  const stripe = useStripe();

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  async function onManagePayment() {
    if (!stripe) return;

    try {
      const response = await api.post(
        "/subscription/manage/update-payment",
        userInputs
      );
      const sessionUrl = response.data.sessionUrl;

      if (sessionUrl) {
        window.location.href = sessionUrl; // Redireciona para o portal do Stripe
      } else {
        console.error("Erro: URL da sessão não recebida.");
      }
    } catch (err) {
      console.error("Erro ao iniciar sessão:", err);
    }
  }

  async function onCancelSubscription() {
    if (!stripe) return;

    try {
      const response = await api.post(
        "/subscription/manage/update-cancel",
        userInputs
      );
      const sessionUrl = response.data.sessionUrl;

      if (sessionUrl) {
        window.location.href = sessionUrl; // Redireciona para o portal do Stripe
      } else {
        console.error("Erro: URL da sessão não recebida.");
      }
    } catch (err) {
      console.error("Erro ao iniciar sessão:", err);
    }
  }

  return (
    <>
      {popupSelected === "Info" && (
        <>
          <div className={styles.section}>
            <h1>Informações Pessoais:</h1>
            <h2>Nome: {userInputs["name"]}</h2>
            <h2>Email: {userInputs["email"]}</h2>
          </div>
          <div className={styles.section}>
            <h1>
              Minhas assinaturas:{" "}
              <Button
                variant={"icon"}
                leftIcon={<FaCreditCard />}
                w={"175px"}
                isDisabled={!userInputs["licenses"]["fundars"]["plan"]}
                onClick={onManagePayment}
              >
                Gerenciar Cartões
              </Button>
            </h1>
            {Object.entries(userInputs["licenses"]).map(
              ([software, license], i) => (
                <div className={styles.subscription} key={i}>
                  <h1>
                    {software === "fundars"
                      ? "FundArs"
                      : software === "quantitars"
                        ? "QuantitArs"
                        : "Complas"}
                  </h1>
                  {license["plan"] !== "" ? (
                    <h2>
                      Meu plano: <LicenseTag plan={license["plan"]} />
                    </h2>
                  ) : (
                    <h2>Meu plano: Nenhum</h2>
                  )}
                  <h2>
                    Status:{" "}
                    <span
                      className={
                        license["status"] === "active"
                          ? styles.textActive
                          : license["status"] === "inactive"
                            ? styles.textInactive
                            : styles.textCanceled
                      }
                    >
                      {license["status"] === "active"
                        ? "Ativo"
                        : license["status"] === "inactive"
                          ? "Inativo"
                          : "Cancelado"}
                    </span>
                  </h2>
                  {license["status"] === "active" &&
                  license["plan"] !== "tester" &&
                  license["plan"] !== "free" ? (
                    <>
                      <h2>
                        Próxima Cobrança: {formatDate(license["expires_at"])}
                      </h2>
                      {license["plan"] !== "tester" && (
                        <Button
                          variant={"delete"}
                          size={"sm"}
                          onClick={onCancelSubscription}
                        >
                          Cancelar Assinatura
                        </Button>
                      )}
                    </>
                  ) : (
                    <h2>
                      Data de expiração:{" "}
                      {license["expires_at"] === ""
                        ? "-"
                        : formatDate(license["expires_at"])}
                    </h2>
                  )}
                </div>
              )
            )}
          </div>
        </>
      )}
    </>
  );
}

export default User;
