import { api } from "../../../../utils/services/api";

export function settingsList(data) {
  return api.post("/settings", data);
}

export function settingsDuplicate(data) {
  return api.post("/settings/duplicate", data);
}

export function settingsEdit(data) {
  return api.post("/settings/edit", data);
}

export function settingsRemove(data) {
  return api.post("/settings/remove", data);
}

export function settingsSave(data) {
  return api.post("/settings/save", data);
}
